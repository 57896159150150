<template>
  <inline-svg :src="path" :width="width" :height="height" :fill="fill" class="app-icon" />
</template>

<script lang="ts">
import Vue from "vue";
import InlineSvg from "vue-inline-svg";

export default Vue.extend({
  name: "AppIcon",
  components: {
    InlineSvg,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
      default: "150",
    },
    height: {
      type: String,
      required: true,
    },
    fill: {
      type: String,
      required: false,
      default: "currentColor",
    },
  },
});
</script>
