import ax from "axios";
import store from "@/store";
import Vue from "vue";
import { getCookie } from "@/services/cookieService";
import { getAuth, getIdToken } from "firebase/auth";

// TODO: Make it better...
const baseUrl = process.env.VUE_APP_API_URL;
const partnerKey = process.env.VUE_APP_PARTNER_KEY;

const patientGenie = ax.create({
  baseURL: baseUrl,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

patientGenie.interceptors.request.use(
  async (config) => {
    if (!config.params) config.params = {};

    if (partnerKey) {
      config.params.key = partnerKey;
    }

    if (store.getters.getReferral) {
      config.params.utm = store.getters.getReferral;
    }

    const token = Vue.prototype.$appConfig.isEmbed ? localStorage.authToken : getCookie("token");

    if (
      (getAuth().currentUser && config.url?.includes("/session")) ||
      config.url?.includes("/user")
    ) {
      const { currentUser } = getAuth();
      config.headers["X-Auth-Token"] = await getIdToken(currentUser);
    }

    if (!config.headers || !token) {
      return config;
    }

    config.headers["X-Session-Token"] = token || "";

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

patientGenie.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status >= 500) {
      store.dispatch("setErrorNotification", { text: error.response.data });
    }

    return Promise.reject(error);
  }
);

// Images  API
const imageApi = ax.create({
  baseURL: `${process.env.VUE_APP_IMG_API_URL}`,
  timeout: 30000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

imageApi.interceptors.request.use(
  async (config) => {
    const { currentUser } = getAuth();
    config.headers["X-Auth-Token"] = await getIdToken(currentUser);

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

imageApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

const profileApi = ax.create({
  baseURL: `${process.env.VUE_APP_PROFILE_API_URL}`,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

profileApi.interceptors.request.use(
  async (config) => {
    if (!config.params) config.params = {};

    if (partnerKey) {
      config.params.key = partnerKey;
    }

    const token = Vue.prototype.$appConfig.isEmbed ? localStorage.authToken : getCookie("token");

    if (!config.headers || !token) {
      return config;
    }

    config.headers["X-Session-Token"] = token || "";
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

profileApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export { patientGenie, imageApi, profileApi };
