import { callPatientGenieApi } from "@/api/apiHelper";
import { IFetchResponse } from "@/types";
import { IContactForm, IContactFormDemoRequest } from "@/types/IContactForm";

export const sendContact = async (contactData: IContactForm): Promise<IFetchResponse> => {
  return await callPatientGenieApi("post", "/contact-us", contactData);
};

export const requestDemo = async (
  contactData: IContactFormDemoRequest
): Promise<IFetchResponse> => {
  return await callPatientGenieApi("post", "/request-demo", contactData);
};

export const applyForJob = async (contactData: FormData): Promise<IFetchResponse> => {
  return await callPatientGenieApi("post", `/join-team`, contactData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
