import { IFetchResponse } from "@/types";
import { IUser, IUserToEdit } from "@/types/IUser";
import { callPatientGenieApi } from "@/api/apiHelper";
import { IPatientHistoryItem, IPatientHistoryParams } from "@/types/IPatientHistory";
import { IPatientJourney, IPatientJourneyParams } from "@/types/IPatientJourney";
import { IProviderShort } from "@/types/IProviders";
import { IPaginationParams } from "@/types/IPaginationParams";

export const getUser = async (): Promise<IFetchResponse<IUser>> => {
  return await callPatientGenieApi("get", "/user");
};

export const updateUser = async (
  updatedUser: IUser | IUserToEdit
): Promise<IFetchResponse<IUser>> => {
  return await callPatientGenieApi("put", "/user", updatedUser);
};

export const answerToOnboarding = async (stepName: string, stepData: Record<string, unknown>) => {
  return await callPatientGenieApi("post", `/user/onboarding/${stepName}`, stepData);
};

export const sendCode = async (email: { email: string }) => {
  return await callPatientGenieApi("post", "/auth-code", email);
};

export const registerUserWithEmail = async (email: { email: string }) => {
  return await callPatientGenieApi("post", "/register", email);
};

export const confirmCode = async (code: { code: number }) => {
  return await callPatientGenieApi("post", "/confirm-code", code);
};

export const getHistory = async ({
  keyword,
  pageNumber,
  pageSize,
}: IPatientHistoryParams): Promise<IFetchResponse<IPatientHistoryItem[]>> => {
  const params = {
    k: keyword,
    p: pageNumber,
    pp: pageSize,
  };

  return await callPatientGenieApi("get", "/user/history", { params });
};

export const removeAllHistory = async () => {
  return await callPatientGenieApi("delete", "/user/history/remove-all");
};

export const removeHistoryItems = async (idsList: string[]) => {
  return await callPatientGenieApi("delete", `/user/history`, { data: idsList });
};

export const getJourneys = async ({
  keyword,
  pageNumber,
  pageSize,
}: IPatientJourneyParams): Promise<IFetchResponse<IPatientJourney[]>> => {
  const params = {
    k: keyword,
    p: pageNumber,
    pp: pageSize,
  };

  return await callPatientGenieApi("get", "/user/journeys", { params });
};

export const removeAllJourneys = async () => {
  return await callPatientGenieApi("delete", "/user/journeys/remove-all");
};

export const removeJourneyItems = async (idsList: string[]) => {
  return await callPatientGenieApi("delete", `/user/journeys`, { data: idsList });
};

export const toggleProviderSave = async (npi: string): Promise<IFetchResponse<boolean>> => {
  return await callPatientGenieApi("post", `/user/saved/providers/${npi}`);
};

export const getSavedProviders = async ({
  keyword,
  page,
  pageSize,
}: IPaginationParams): Promise<IFetchResponse<IProviderShort[]>> => {
  const params = {
    k: keyword,
    p: page,
    pp: pageSize,
  };

  return await callPatientGenieApi("get", "/user/saved/providers", { params });
};
