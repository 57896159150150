import Vue from "vue";
import Vuex from "vuex";
import { IAutocompleteResult } from "@/types";
import organizations from "@/store/modules/organizations";
import providers from "@/store/modules/providers";
import providersFilters from "@/store/modules/providersFilters";
import patientGPT from "@/store/modules/patientGPT";
import patientProfile from "@/store/modules/patientProfile";
import user from "@/store/modules/user";
import compareProviders from "@/store/modules/compareProviders";
import saveProviders from "@/store/modules/saveProviders";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    organizations,
    providers,
    providersFilters,
    patientGPT,
    patientProfile,
    user,
    compareProviders,
    saveProviders,
  },
  state: {
    isHeaderWithSearch: false,
    searchReason: "",
    autocompleteResults: [] as IAutocompleteResult[],
    isDrawerOpened: false,
    isSearchFromMapsPage: false,
    errorNotification: null,
    isNeedToUpdateToken: false,
    referral: "",
  },
  getters: {
    getIsHeaderWithSearch: (state) => state.isHeaderWithSearch,
    getSearchReason: (state) => state.searchReason,
    getAutocompleteResults: (state) => state.autocompleteResults,
    getIsDrawerOpened: (state) => state.isDrawerOpened,
    getIsSearchFromMapsPage: (state) => state.isSearchFromMapsPage,
    getErrorNotification: (state) => state.errorNotification,
    getIsNeedToUpdateToken: (state) => state.isNeedToUpdateToken,
    getReferral: (state) => state.referral,
  },
  mutations: {
    SET_IS_HEADER_WITH_SEARCH: (state, isHeaderWithSearch) => {
      state.isHeaderWithSearch = isHeaderWithSearch;
    },
    SET_SEARCH_REASON: (state, searchReason) => {
      state.searchReason = searchReason;
    },
    SET_AUTOCOMPLETE_RESULTS: (state, autocompleteResults) => {
      state.autocompleteResults = autocompleteResults;
    },
    SET_IS_DRAWER_OPENED: (state, isDrawerOpened) => {
      state.isDrawerOpened = isDrawerOpened;
    },
    SET_IS_SEARCH_FROM_MAPS_PAGE: (state, isSearchFromMapsPage) => {
      state.isSearchFromMapsPage = isSearchFromMapsPage;
    },
    SET_ERROR_NOTIFICATION(state, errorNotification) {
      state.errorNotification = errorNotification;
    },
    SET_IS_NEED_TO_UPDATE_TOKEN(state, isNeedToUpdateToken) {
      state.isNeedToUpdateToken = isNeedToUpdateToken;
    },
    SET_REFERRAL(state, referral) {
      state.referral = referral;
    },
  },
  actions: {
    setIsHeaderWithSearch: ({ commit }, isHeaderWithSearch) => {
      commit("SET_IS_HEADER_WITH_SEARCH", isHeaderWithSearch);
    },
    setSearchReason: ({ commit }, searchReason) => {
      commit("SET_SEARCH_REASON", searchReason);
    },
    setAutocompleteResults: ({ commit }, autocompleteResults) => {
      commit("SET_AUTOCOMPLETE_RESULTS", autocompleteResults);
    },
    setIsDrawerOpened: ({ commit }, isDrawerOpened) => {
      commit("SET_IS_DRAWER_OPENED", isDrawerOpened);
    },
    setIsSearchFromMapsPage: ({ commit }, isSearchFromMapsPage) => {
      commit("SET_IS_SEARCH_FROM_MAPS_PAGE", isSearchFromMapsPage);
    },
    setErrorNotification({ commit }, errorNotification) {
      commit("SET_ERROR_NOTIFICATION", errorNotification);
    },
    setIsNeedToUpdateToken({ commit }, isNeedToUpdateToken) {
      commit("SET_IS_NEED_TO_UPDATE_TOKEN", isNeedToUpdateToken);
    },
    setReferral({ commit }, referral) {
      commit("SET_REFERRAL", referral);
    },
  },
});
