import { Commit } from "vuex";

interface ISaveProvidersState {
  isShowLoginDialog: boolean;
  isShowSaveBanner: boolean;
}

export default {
  namespaced: true,
  state: (): ISaveProvidersState => ({
    isShowLoginDialog: false,
    isShowSaveBanner: false,
  }),
  getters: {
    getIsShowLoginDialog: (state: ISaveProvidersState) => {
      return state.isShowLoginDialog;
    },
    getIsShowSaveBanner: (state: ISaveProvidersState) => {
      return state.isShowSaveBanner;
    },
  },
  mutations: {
    SET_IS_SHOW_LOGIN_DIALOG(state: ISaveProvidersState, isShowLoginDialog: boolean) {
      state.isShowLoginDialog = isShowLoginDialog;
    },
    SET_IS_SHOW_SAVE_BANNER(state: ISaveProvidersState, isShowSaveBanner: boolean) {
      state.isShowSaveBanner = isShowSaveBanner;
    },
  },
  actions: {
    setIsShowLoginDialog({ commit }: { commit: Commit }, isShowLoginDialog: boolean) {
      commit("SET_IS_SHOW_LOGIN_DIALOG", isShowLoginDialog);
    },
    setIsShowSaveBanner({ commit }: { commit: Commit }, isShowSaveBanner: boolean) {
      commit("SET_IS_SHOW_SAVE_BANNER", isShowSaveBanner);
    },
  },
};
