const zocDocWidgetUrl =
  "https://d1uhlocgth3qyq.cloudfront.net/schedulewidget-install-script/scripts/schedulewidget-install-script.js";
const zocDocWidgetId = "zd-schedule-widget-install-script";
const zocDocComponentSelector = "#zd-schedule-widget-container";

export const initSchedulerParams = (key: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window._zdScheduleWidgetConfig_x = {
    affiliateid: key, // required, string
    npi: "", // required, string
    debugmode: true,
    //textcolor: "var(--color-purple-primary)", // optional, hex color for text on widget
    //buttonbackgroundcolor: '006400', // optional, hex color for button backgrounds
    //buttontextcolor: 'EEFFEE', // optional, hex color for button text
    //hoverbuttonbackgroundcolor: '006400', // optional, hex color for button background on hover
    //hoverbuttontextcolor: 'EEFFEE', // optional, hex color for button text on hover
    //customtrackingid: 'tid_1234', // optional, string
    //locationid: 'lo_idString', // optional, string
  };
};

export const mountWidget = (npi: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window._zdScheduleWidgetConfig_x.npi = npi;
  const widgetScript = document.createElement("script");
  widgetScript.id = zocDocWidgetId;
  widgetScript.type = "text/javascript";
  widgetScript.src = zocDocWidgetUrl;
  widgetScript.setAttribute("defer", "");
  document.body.append(widgetScript);
};

export const removeWidget = () => {
  const widgetComponent = document.querySelector(zocDocComponentSelector);
  if (widgetComponent) widgetComponent.innerHTML = "";

  const widgetScriptSelector = `#${zocDocWidgetId}`;
  const widgetScript = document.querySelector(widgetScriptSelector);
  if (widgetScript?.parentNode) widgetScript.parentNode.removeChild(widgetScript);
};
