import { callPatientGenieApi } from "@/api/apiHelper";
import { IFetchResponse, IAutocompleteResult } from "@/types";

export const getWords = async (word: string): Promise<IFetchResponse<IAutocompleteResult[]>> => {
  return await callPatientGenieApi("get", "/autocomplete", {
    params: { keyword: word },
  });
};

export const getSymptoms = async (word: string): Promise<IFetchResponse<IAutocompleteResult[]>> => {
  return await callPatientGenieApi("get", "/autocomplete-symptom", {
    params: { keyword: word },
  });
};
