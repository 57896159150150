import { IFetchResponse, IGptResponse, IGptRequest } from "@/types";
import { AxiosResponse } from "axios";
import { patientGenie } from "@/plugins/axios";
import { callPatientGenieApi } from "@/api/apiHelper";
import { IGptVoteRequest } from "@/types/IGptRequest";

export const getGPTResults = async (body: IGptRequest): Promise<IFetchResponse<IGptResponse>> => {
  try {
    const response: AxiosResponse = await patientGenie.post("/patient-gpt", body, { timeout: 0 });
    return { data: response?.data, error: null } as IFetchResponse;
  } catch (error: any | unknown) {
    return {
      data: null,
      error,
    } as IFetchResponse;
  }
};

export const voteForGPTResult = async (
  body: IGptVoteRequest
): Promise<IFetchResponse<IGptResponse>> => {
  return await callPatientGenieApi("post", "/patient-gpt/vote", body);
};
