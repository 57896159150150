import * as search from "@/api/search";
import * as conversation from "@/api/conversation";
import * as session from "@/api/session";
import * as autocomplete from "@/api/autocomplete";
import * as provider from "@/api/provider";
import * as insurance from "@/api/insurance";
import * as conclusion from "@/api/conclusion";
import * as location from "@/api/location";
import * as gpt from "@/api/gpt";
import * as contact from "@/api/contact";
import * as shortParams from "@/api/shortParams";
import * as organizations from "@/api/organizations";
import * as user from "@/api/user";
import * as features from "@/api/features";

export default {
  search,
  conversation,
  session,
  autocomplete,
  provider,
  insurance,
  conclusion,
  location,
  gpt,
  contact,
  shortParams,
  organizations,
  user,
  features,
};
