<template>
  <v-app
    v-if="!featuresLoading && !tokenLoading"
    id="app"
    :class="[{ 'pg-embedded': $appConfig.isEmbed }, 'app']"
  >
    <template v-if="$appConfig.isEmbed">
      <v-main class="view-wrapper" :style="isDefaultFooter ? `min-height: 100vh;` : ''">
        <transition mode="out-in" name="fade">
          <router-view v-if="!getIsAuthLoading" />
        </transition>
      </v-main>
    </template>
    <template v-else>
      <TheHeader v-if="$route.name && !isMapsPage" />

      <TheDrawer v-if="isShowDrawer" />

      <v-main class="view-wrapper" :style="isDefaultFooter ? `min-height: 100vh;` : ''">
        <transition mode="out-in" name="fade">
          <router-view v-if="!getIsAuthLoading" />
        </transition>
      </v-main>

      <TheFooter v-if="isShowFooter" :is-default-footer="isDefaultFooter" />

      <transition name="fade">
        <AppErrorNotification v-if="getErrorNotification" />
      </transition>
    </template>

    <TheSessionModal @fetchSessionToken="fetchSessionToken" />

    <CompareProvidersWidget />

    <div id="recaptcha-container"></div>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import api from "@/api";
import { mapActions, mapGetters } from "vuex";
import { getCookie } from "@/services/cookieService";
import { onAuthStateChanged, getAuth, applyActionCode } from "firebase/auth";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheDrawer from "@/components/TheDrawer.vue";
import AppErrorNotification from "@/components/AppComponents/AppErrorNotification.vue";
import TheSessionModal from "@/components/TheSessionModal.vue";
import CompareProvidersWidget from "@/components/Compare/CompareProvidersWidget.vue";

export default Vue.extend({
  name: "App",
  components: {
    CompareProvidersWidget,
    AppErrorNotification,
    TheHeader,
    TheFooter,
    TheDrawer,
    TheSessionModal,
  },
  data() {
    return {
      currentAuthToken: this.$appConfig.isEmbed
        ? localStorage.getItem("authToken") || ""
        : getCookie("token") || "",
      urlKeyword: null as null | string,
      urlPath: null as null | string,
      swRefreshing: false,
      swRegistration: null as null | any,
      swUpdateExists: false,
      mapRoutes: ["Maps"],
      tokenLoading: false,
      featuresLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getErrorNotification: "getErrorNotification",
      getUser: "user/getUser",
      getIsAuthLoading: "user/getIsAuthLoading",
    }),
    isUserIncomplete(): boolean {
      return this.getUser && (!this.getUser?.isEmailConfirmed || this.getUser?.onboarding?.length);
    },
    isAuthRoute(): boolean {
      if (!this.$route.name) return false;
      return this.$route.meta?.isAuthRoute;
    },
    isDefaultFooter(): boolean {
      if (!this.$route.name) return true;
      return !this.$route.meta?.isSmallFooter;
    },
    isMapsPage(): boolean {
      if (!this.$route.name) return false;
      return this.mapRoutes.includes(this.$route.name);
    },
    isShowFooter(): boolean {
      return !this.isMapsPage && this.$route.name !== "Conversation";
    },
    isShowDrawer(): boolean {
      return !this.isMapsPage && !this.$appConfig.isIntegration && !this.$appConfig.isEmbed;
    },
    authToken: {
      get(): null | string {
        return this.currentAuthToken;
      },
      set(data: string) {
        this.currentAuthToken = data;
        if (this.$appConfig.isEmbed) {
          localStorage.setItem("authToken", data);
          return;
        }
        document.cookie = `token=${data}; path=/; SameSite=None; Secure`;
      },
    },
  },
  async created() {
    this.featuresLoading = true;
    const { data } = await api.features.getFeatures();
    this.featuresLoading = false;

    if (data) {
      this.$appConfig.isTriageOn = data.triage;
      this.$appConfig.isPricingOn = data.pricing;
      this.$appConfig.isSchedulingOn = data.scheduling;
      this.$appConfig.isOnlineProfilesOn = data.onlineProfiles;
      this.$appConfig.isInternetSearchOn = data.internetSearch;
      this.$appConfig.isInternetSearchProviderOn = data.internetSearchProvider;
      this.$appConfig.isPatientNavigationOn = data.patientNavigation;
      this.$appConfig.isProviderScoresOn = data.providerScores;
      this.$appConfig.sessionExpirationTimeSeconds = data.sessionExpirationTime;
    } else {
      this.$appConfig.isTriageOn = false;
      this.$appConfig.isPricingOn = false;
      this.$appConfig.isSchedulingOn = false;
      this.$appConfig.isOnlineProfilesOn = false;
      this.$appConfig.isInternetSearchOn = false;
      this.$appConfig.isInternetSearchProviderOn = false;
      this.$appConfig.isPatientNavigationOn = false;
      this.$appConfig.isProviderScoresOn = false;
      this.$appConfig.sessionExpirationTimeSeconds = 900;
    }

    if ("serviceWorker" in navigator) {
      // Listen for our custom event from the SW registration
      document.addEventListener("swUpdated", this.updateAvailable, { once: true });
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.swRefreshing) return;
        this.swRefreshing = true;
        window.location.reload();
      });
    }
  },
  async mounted() {
    if (this.$appConfig.isEmbed) {
      console.log("pg-embedded-version: ", process.env.VUE_APP_CODE_VERSION);
      document.documentElement.style.overflow = "auto";
    } else {
      console.log(
        `%cVersion: ${process.env.VUE_APP_CODE_VERSION}`,
        "color: green; font-size: 16px"
      );
    }

    this.setIsAuthLoading(true);

    if (!this.authToken) {
      await this.fetchSessionToken();
    }

    await this.firebaseAuth();
  },
  watch: {
    "$route.query"(value) {
      if (value.utm) {
        this.setReferral(value.utm);
        const query = { ...value };
        delete query.utm;
        this.$router.replace({ query });
      }
    },
  },
  methods: {
    ...mapActions(["setReferral"]),
    ...mapActions("user", ["setUser", "setIsAuthLoading", "signOut"]),
    async fetchAndSetUser() {
      const { data } = await api.user.getUser();

      if (!data) return;

      await this.setUser(data);
    },
    async handleRedirects() {
      if (this.getUser && !this.getUser.isEmailConfirmed) {
        if (this.$route.name !== "EmailConfirmation") {
          await this.$router.push({ name: "EmailConfirmation" });
        }
        return;
      }

      if (this.$route.name !== "Onboarding" && this.isUserIncomplete) {
        await this.$router.push({ name: "Onboarding" });
        return;
      }

      if (this.isAuthRoute && this.getUser) {
        await this.$router.push((this.$route.query.redirect as string) || "/");
      }
    },
    async firebaseAuth() {
      this.setIsAuthLoading(true);

      await this.handleOobCode();

      onAuthStateChanged(getAuth(), async (userFB: any) => {
        this.setIsAuthLoading(true);

        try {
          if (!userFB) {
            if (!localStorage.getItem("authToken") && !getCookie("token")) {
              await this.fetchSessionToken();
              await this.signOut();
            }

            this.setIsAuthLoading(false);
            return;
          }

          await this.fetchSessionToken();
          await this.fetchAndSetUser();

          this.setIsAuthLoading(false);
        } catch (error) {
          if (error instanceof Error) console.error(error.message);
        } finally {
          this.setIsAuthLoading(false);
          await this.handleRedirects();
        }
      });
    },
    async fetchSessionToken() {
      this.tokenLoading = true;
      const { data, error } = await api.session.start();

      if (data && !error) {
        this.authToken = data.token;
      }

      this.tokenLoading = false;
    },
    updateAvailable(event: any) {
      // Store the SW registration
      this.swRegistration = event.detail;
      this.swUpdateExists = true;
      this.refreshApp();
    },
    refreshApp() {
      this.swUpdateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.swRegistration || !this.swRegistration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.swRegistration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    handleOobCode() {
      const urlParams = new URLSearchParams(window.location.search);
      const queryMode = urlParams.get("mode");

      if (!queryMode) return;

      const oobCode = urlParams.get("oobCode") || "";

      if (queryMode === "verifyEmail" && oobCode) {
        this.verifyEmail(oobCode);
      }
    },
    async verifyEmail(oobCode: string) {
      try {
        await applyActionCode(getAuth(), oobCode);

        if (this.$route.name !== "Onboarding") {
          await this.$router.push({ name: "Onboarding" });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
</script>

<style lang="scss">
@import "@/assets/style/global";
@import "@/assets/style/mixins";

.view-wrapper {
  .view {
    background-color: var(--color-purple-light);
  }
}

.app {
  min-height: calc(100vh);
  background-color: var(--color-purple-light) !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.pg-embedded {
  background-color: transparent !important;

  .autocomplete-overlay {
    background-color: transparent !important;
  }
}
</style>
