<template>
  <ConsumerHeader v-if="$route.meta.isConsumer" />
  <BusinessHeader v-else />
</template>

<script lang="ts">
import Vue from "vue";
import ConsumerHeader from "@/components/Header/ConsumerHeader.vue";
import BusinessHeader from "@/components/Header/BusinessHeader.vue";

export default Vue.extend({
  name: "TheHeader",
  components: {
    BusinessHeader,
    ConsumerHeader,
  },
});
</script>
