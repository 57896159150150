// TODO use store to handle providers filters
import { ActionContext, Commit } from "vuex";
import { IInsuranceCompany, IInsurancePlan } from "@/types";
import langs from "../../../public/langs.json";
import api from "@/api";

export interface IProvidersFilters {
  selectedGenders: string[];
  selectedExperience: string[];
  genderCheckboxes: {
    title: string;
    value: string;
  }[];
  defaultInsuranceCompany: IInsuranceCompany;
  organizationSearchKeyword: string;
  insurancesList: IInsuranceCompany[];
  languagesList: string[];
  distanceList: number[];
  selectedInsuranceCompany: IInsuranceCompany;
  selectedPlans: string[];
  insuranceSearchKeyword: string;
  timeOfDayOptions: {
    checked: boolean;
    value: string;
    label: string;
  }[];
  experienceCheckboxes: {
    title: string;
    value: string;
    isChecked: boolean;
  }[];
}

export interface IProvidersFiltersState {
  providersFilters: IProvidersFilters;
}

export default {
  namespaced: true,
  state: (): IProvidersFiltersState => ({
    providersFilters: {
      selectedGenders: [],
      selectedExperience: [],
      genderCheckboxes: [
        {
          title: "Female",
          value: "f",
        },
        {
          title: "Male",
          value: "m",
        },
      ],
      defaultInsuranceCompany: {
        insuranceBrand: "",
        insuranceName: "",
        plans: [],
      },
      organizationSearchKeyword: "",
      insurancesList: [],
      languagesList: [...langs],
      distanceList: [5, 15, 25, 50, 75, 100, 200, 0],
      selectedInsuranceCompany: {
        insuranceBrand: "",
        insuranceName: "",
        plans: [],
      },
      selectedPlans: [],
      insuranceSearchKeyword: "",
      timeOfDayOptions: [
        {
          checked: false,
          value: "Early morning",
          label: "Starts before 10 am",
        },
        {
          checked: false,
          value: "Morning",
          label: "Starts before 12 pm",
        },
        {
          checked: false,
          value: "Afternoon",
          label: "Starts after 12 pm",
        },
        {
          checked: false,
          value: "Evening",
          label: "Starts after 5 pm",
        },
      ],
      experienceCheckboxes: [
        {
          title: "0-5 years",
          value: "0-5",
          isChecked: false,
        },
        {
          title: "5-10 years",
          value: "5-10",
          isChecked: false,
        },
        {
          title: "10+ years",
          value: "10+",
          isChecked: false,
        },
      ],
    },
  }),
  getters: {
    getProvidersFilters: (state: IProvidersFiltersState): IProvidersFilters => {
      return state.providersFilters;
    },
    getFilteredInsurances: (state: IProvidersFiltersState): IInsuranceCompany[] | string[] => {
      if (!state.providersFilters.insuranceSearchKeyword) {
        return state.providersFilters.insurancesList;
      }

      return state.providersFilters.insurancesList.filter((insurance) => {
        return insurance.insuranceName
          .toLowerCase()
          .includes(state.providersFilters.insuranceSearchKeyword.toLowerCase());
      });
    },
    getSelectedInsurancePlans: (state: IProvidersFiltersState): IInsurancePlan[] => {
      if (!state.providersFilters.selectedInsuranceCompany) return [];
      return state.providersFilters.selectedInsuranceCompany.plans;
    },
  },
  mutations: {
    SET_PROVIDERS_FILTERS: (state: IProvidersFiltersState, filters: IProvidersFilters) => {
      state.providersFilters = JSON.parse(JSON.stringify(filters));
    },
  },
  actions: {
    setProvidersFilters: ({ commit }: { commit: Commit }, providerFilters: IProvidersFilters) => {
      commit("SET_PROVIDERS_FILTERS", providerFilters);
    },

    fetchInsurances: async ({
      state,
      commit,
    }: {
      commit: Commit;
      state: IProvidersFiltersState;
    }) => {
      const { data } = await api.insurance.get();

      if (data) {
        commit("SET_PROVIDERS_FILTERS", {
          ...state.providersFilters,
          insurancesList: data.sort((a, b) => a.insuranceName.localeCompare(b.insuranceName)),
        });
      }
    },

    selectInsuranceCompany: (
      { state, commit, rootGetters, dispatch }: ActionContext<IProvidersFiltersState, any>,
      company: string
    ) => {
      dispatch(
        "providers/setProvidersSearchRequest",
        {
          ...rootGetters["providers/getProvidersSearchRequest"],
          filters: {
            ...rootGetters["providers/getProvidersSearchRequest"].filters,
            insuranceCompany: company,
          },
        },
        { root: true }
      );

      const selectedCompany = state.providersFilters.insurancesList.find(
        (i) =>
          i.insuranceName ===
          rootGetters["providers/getProvidersSearchRequest"].filters.insuranceCompany
      );

      commit("SET_PROVIDERS_FILTERS", {
        ...state.providersFilters,
        selectedInsuranceCompany: selectedCompany || state.providersFilters.defaultInsuranceCompany,
      });

      dispatch("submitFilters");
    },
    handleExperienceCheckboxChange: (
      { dispatch, rootGetters }: ActionContext<IProvidersFiltersState, any>,
      { isChecked, value }: { isChecked: boolean; value: string }
    ) => {
      dispatch(
        "providers/setProvidersSearchRequest",
        {
          ...rootGetters["providers/getProvidersSearchRequest"],
          filters: {
            ...rootGetters["providers/getProvidersSearchRequest"].filters,
            experience: isChecked
              ? [...rootGetters["providers/getProvidersSearchRequest"].filters.experience, value]
              : rootGetters["providers/getProvidersSearchRequest"].filters.experience.filter(
                  (item: string) => item !== value
                ),
          },
        },
        { root: true }
      );

      dispatch("submitFilters");
    },
    handleFilterChange: (
      { dispatch, rootGetters }: ActionContext<IProvidersFiltersState, any>,
      { name, event }: { name: string; event: string }
    ) => {
      dispatch(
        "providers/setProvidersSearchRequest",
        {
          ...rootGetters["providers/getProvidersSearchRequest"],
          filters: {
            ...rootGetters["providers/getProvidersSearchRequest"].filters,
            [name]: event === null ? false : event,
          },
        },
        { root: true }
      );

      dispatch("submitFilters");
    },
    handleGenderCheckboxChange: (
      { dispatch, rootGetters }: ActionContext<IProvidersFiltersState, any>,
      {
        gender,
        event,
      }: {
        gender: "male" | "female";
        event: boolean;
      }
    ) => {
      dispatch(
        "providers/setProvidersSearchRequest",
        {
          ...rootGetters["providers/getProvidersSearchRequest"],
          filters: {
            ...rootGetters["providers/getProvidersSearchRequest"].filters,
            gender: {
              ...rootGetters["providers/getProvidersSearchRequest"].filters.gender,
              [gender]: event ? true : false,
            },
          },
        },
        { root: true }
      );

      dispatch("submitFilters");
    },
    handleGenderSelectChange: (
      { commit, state, dispatch, rootGetters }: ActionContext<IProvidersFiltersState, any>,
      selectedGenders: "Male"[] | "Female"[]
    ) => {
      commit("SET_PROVIDERS_FILTERS", {
        ...state.providersFilters,
        selectedGenders: selectedGenders,
      });

      dispatch(
        "providers/setProvidersSearchRequest",
        {
          ...rootGetters["providers/getProvidersSearchRequest"],
          filters: {
            ...rootGetters["providers/getProvidersSearchRequest"].filters,
            gender: {
              female: state.providersFilters.selectedGenders.includes("Female"),
              male: state.providersFilters.selectedGenders.includes("Male"),
            },
          },
        },
        { root: true }
      );

      dispatch("submitFilters");
    },

    submitFilters: ({ dispatch, rootGetters }: ActionContext<IProvidersFiltersState, any>) => {
      dispatch(
        "providers/setProvidersSearchRequest",
        {
          ...rootGetters["providers/getProvidersSearchRequest"],
          params: {
            ...rootGetters["providers/getProvidersSearchRequest"].params,
            page: 0,
          },
        },
        {
          root: true,
        }
      );

      dispatch("providers/setProvidersList", [], { root: true });
      dispatch("providers/fetchProvidersList", null, { root: true });
    },

    resetField: (
      { dispatch, rootGetters }: ActionContext<IProvidersFiltersState, any>,
      fieldName: string
    ) => {
      dispatch(
        "providers/setProvidersSearchRequest",
        {
          ...rootGetters["providers/getProvidersSearchRequest"],
          filters: {
            ...rootGetters["providers/getProvidersSearchRequest"].filters,
            [fieldName]:
              rootGetters["providers/getDefaultProvidersSearchRequest"].filters[fieldName],
          },
        },
        { root: true }
      );

      dispatch("submitFilters");
    },
    resetAllFields: ({
      state,
      commit,
      dispatch,
      rootGetters,
    }: ActionContext<IProvidersFiltersState, any>) => {
      commit("SET_PROVIDERS_FILTERS", {
        ...state.providersFilters,
        selectedInsuranceCompany: state.providersFilters.defaultInsuranceCompany,
        selectedPlans: [],
        selectedGenders: [],
        selectedExperience: [],
      });

      dispatch(
        "providers/setProvidersSearchRequest",
        {
          ...rootGetters["providers/getProvidersSearchRequest"],
          filters: rootGetters["providers/getDefaultProvidersSearchRequest"].filters,
        },
        { root: true }
      );

      dispatch("resetFilters");
    },
    resetFilters: ({
      state,
      dispatch,
      rootGetters,
      commit,
    }: ActionContext<IProvidersFiltersState, any>) => {
      dispatch(
        "providers/setProvidersSearchRequest",
        {
          ...rootGetters["providers/getProvidersSearchRequest"],
          filters: rootGetters["providers/getDefaultProvidersSearchRequest"].filters,
        },
        { root: true }
      );

      commit("SET_PROVIDERS_FILTERS", {
        ...state.providersFilters,
        experienceCheckboxes: state.providersFilters.experienceCheckboxes.map((item) => {
          return {
            ...item,
            isChecked: false,
          };
        }),
      });

      dispatch("submitFilters");
    },
  },
};
