export default function throttle(callee: (...args: any[]) => void, timeout: number) {
  let timer = undefined as undefined | NodeJS.Timeout;

  return function perform(...args: any[]) {
    if (timer) return;

    timer = setTimeout(() => {
      callee(...args);

      clearTimeout(timer);
      timer = undefined;
    }, timeout);
  };
}
