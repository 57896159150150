const consumerRoutes = [
  {
    path: "/consumer",
    name: "ConsumersHome",
    component: () => import("@/views/ConsumersHomePage.vue"),
    meta: { title: "Find a healthcare provider" },
  },
  {
    path: "/conversation/:id",
    name: "Conversation",
    component: () => import("@/views/ConversationPage.vue"),
    meta: {
      title: "Triage - Find a healthcare provider",
      allowEmbed: true,
      isSmallFooter: true,
      isEndSessionRedirect: true,
    },
  },
  {
    path: "/search/:path/:value",
    name: "SearchResults",
    component: () => import("@/views/SearchResultsPage.vue"),
    meta: {
      title: "Results - Find a healthcare provider",
      allowEmbed: true,
      isSmallFooter: true,
    },
  },
  {
    path: "/city/:city/:path/:value",
    name: "SearchResultsByCity",
    component: () => import("@/views/SearchResultsPage.vue"),
    meta: {
      title: "Results - Find a healthcare provider",
      allowEmbed: true,
      isSmallFooter: true,
    },
  },
  {
    path: "/maps/:path/:value",
    name: "Maps",
    component: () => import("@/views/MapsPage.vue"),
    meta: {
      title: "Results on the map - Find a healthcare provider",
      allowEmbed: true,
    },
  },
  {
    path: "/provider-profile/:npi",
    name: "ProviderProfile",
    component: () => import("@/views/ProviderProfilePage.vue"),
    meta: { title: "Provider profile", allowEmbed: true, isSmallFooter: true },
  },
  {
    path: "/organization-profile/:id",
    name: "OrganizationProfile",
    component: () => import("@/views/OrganizationProfilePage.vue"),
    meta: { title: "Organization profile", allowEmbed: true, isSmallFooter: true },
  },
  {
    path: "/compare-providers",
    name: "CompareProviders",
    component: () => import("@/views/CompareProvidersPage.vue"),
    meta: {
      title: "Compare healthcare providers",
      allowEmbed: true,
      isSmallFooter: true,
      isEndSessionRedirect: true,
    },
  },
  {
    path: "/patient-profile",
    name: "PatientProfile",
    component: () => import("@/views/PatientProfile/PatientProfilePage.vue"),
    meta: {
      title: "Patient profile",
      allowEmbed: true,
      requiresAuth: true,
      isSmallFooter: true,
      isEndSessionRedirect: true,
    },
    children: [
      {
        path: "info",
        name: "PatientProfileInfo",
        component: () => import("@/views/PatientProfile/PatientProfileInfoPage.vue"),
        meta: {
          title: "Patient profile information",
          allowEmbed: true,
          requiresAuth: true,
          isSmallFooter: true,
          isEndSessionRedirect: true,
        },
      },
      {
        path: "history",
        name: "PatientProfileHistoryAndJourney",
        component: () => import("@/views/PatientProfile/PatientProfileHistoryAndJourneyPage.vue"),
        meta: {
          title: "Patient profile history and journey",
          allowEmbed: true,
          requiresAuth: true,
          isSmallFooter: true,
          isEndSessionRedirect: true,
        },
      },
      {
        path: "saved",
        name: "PatientProfileSavedProviders",
        component: () => import("@/views/PatientProfile/PatientProfileSavedProvidersPage.vue"),
        meta: {
          title: "Patient saved providers",
          allowEmbed: true,
          requiresAuth: true,
          isSmallFooter: true,
          isEndSessionRedirect: true,
        },
      },
      {
        path: "settings",
        name: "PatientProfileSettings",
        component: () => import("@/views/PatientProfile/PatientProfileSettingsPage.vue"),
        meta: {
          title: "Patient profile settings",
          allowEmbed: true,
          requiresAuth: true,
          isSmallFooter: true,
          isEndSessionRedirect: true,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginPage.vue"),
    meta: { title: "PatientGenie Login", isAuthRoute: true, isSmallFooter: true },
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("@/views/SignupPage.vue"),
    meta: { title: "PatientGenie Signup", isAuthRoute: true, isSmallFooter: true },
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: () => import("@/views/OnboardingPage.vue"),
    meta: {
      title: "PatientGenie",
      requiresAuth: true,
      isAuthRoute: true,
      isSmallFooter: true,
      isEndSessionRedirect: true,
    },
  },
  {
    path: "/email-confirmation",
    name: "EmailConfirmation",
    component: () => import("@/views/EmailConfirmationPage.vue"),
    meta: {
      title: "PatientGenie e-mail confirmation",
      requiresAuth: true,
      isAuthRoute: true,
      isSmallFooter: true,
      isEndSessionRedirect: true,
    },
  },
];

consumerRoutes.forEach((route) => {
  route.meta = {
    ...route.meta,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    isConsumer: true,
  };

  if (route.children) {
    route.children.forEach((childRoute) => {
      childRoute.meta = {
        ...childRoute.meta,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        isConsumer: true,
      };
    });
  }
});

export default consumerRoutes;
