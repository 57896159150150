import { IGptRequest, IGptResponse } from "@/types";
import api from "@/api";
import { ActionContext, Commit } from "vuex";
import { IProvidersRequestAssumptions } from "@/types/IProvidersRequest";
import { PATHS_WITH_ASSUMPTIONS, WAITING_TEXT } from "@/constants/patientGPT";

export interface IPatientGpt {
  results: null | IGptResponse;
  requestParams: IGptRequest;
  resultsStack: IGptResponse[];
  keywordStack: string[];
  requestStack: IGptRequest[];
  textForTyping: string;
  waitingText: string;
  pathsWithAssumptions: string[];
}

export interface IPatientGptState {
  patientGpt: IPatientGpt;
  defaultPatientGpt: IPatientGpt;
}

export default {
  namespaced: true,
  state: (): IPatientGptState => ({
    patientGpt: {
      results: null,
      requestParams: {
        path: "",
        keyword: "",
      },
      resultsStack: [],
      keywordStack: [],
      requestStack: [],
      textForTyping: "",
      waitingText: WAITING_TEXT,
      pathsWithAssumptions: PATHS_WITH_ASSUMPTIONS,
    },
    defaultPatientGpt: {
      results: null,
      requestParams: {
        path: "",
        keyword: "",
      },
      resultsStack: [],
      keywordStack: [],
      requestStack: [],
      textForTyping: "",
      waitingText: WAITING_TEXT,
      pathsWithAssumptions: PATHS_WITH_ASSUMPTIONS,
    },
  }),
  getters: {
    getPatientGpt: (state: IPatientGptState): IPatientGpt => {
      return state.patientGpt;
    },
    getDefaultPatientGpt: (state: IPatientGptState): IPatientGpt => {
      return state.defaultPatientGpt;
    },
    getAssumptions: (state: IPatientGptState): IProvidersRequestAssumptions => {
      const assumptions = {
        taxonomyCodes:
          state.patientGpt.results?.options
            .filter((o) => o.path === "specialty")
            .map((o) => o.code) || [],
        cptCodes:
          state.patientGpt.results?.options
            .filter((o) => o.path === "procedure")
            .map((o) => o.code) || [],
        icdCodes:
          state.patientGpt.results?.options
            .filter((o) => o.path === "disease")
            .map((o) => o.code) || [],
      };

      const lastRequest = state.patientGpt.requestStack[state.patientGpt.requestStack.length - 1];

      if (!lastRequest?.code) return assumptions;

      switch (lastRequest?.path) {
        case "disease":
          assumptions.icdCodes.push(lastRequest.code as string);
          break;
        case "procedure":
          assumptions.cptCodes.push(lastRequest.code as string);
          break;
        case "specialty":
          assumptions.taxonomyCodes.push(lastRequest.code as string);
          break;
      }

      return assumptions;
    },
  },
  mutations: {
    SET_PATIENT_GPT: (state: IPatientGptState, patientGpt: IPatientGpt) => {
      state.patientGpt = JSON.parse(JSON.stringify(patientGpt));
    },
  },
  actions: {
    setPatientGpt: ({ commit }: { commit: Commit }, patientGpt: IPatientGpt) => {
      commit("SET_PATIENT_GPT", patientGpt);
    },
    fetchPatientGptResults: async ({ commit, state }: ActionContext<IPatientGptState, any>) => {
      commit("SET_PATIENT_GPT", {
        ...state.patientGpt,
        results: null,
      });
      commit("SET_PATIENT_GPT", {
        ...state.patientGpt,
        textForTyping: "",
      });

      const { data, error } = await api.gpt.getGPTResults(state.patientGpt.requestParams);

      if (error) {
        commit("SET_PATIENT_GPT", {
          ...state.patientGpt,
          waitingText: "Something went wrong. Please try again later.",
        });

        return;
      }

      if (!data) return;

      commit("SET_PATIENT_GPT", {
        ...state.patientGpt,
        results: data,
        resultsStack: [...state.patientGpt.resultsStack, data],
      });
    },
  },
};
