import { patientGenie, profileApi } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { IFetchResponse } from "@/types";

type TMethod = "get" | "post" | "put" | "delete";

export const callPatientGenieApi = async (method: TMethod, url: string, ...args: any[]) => {
  try {
    const response: AxiosResponse = await patientGenie[method](url, ...args);
    return { data: response?.data, error: null } as IFetchResponse;
  } catch (error: any | unknown) {
    return {
      data: null,
      error,
    } as IFetchResponse;
  }
};

export const callProfileApi = async (
  method: TMethod,
  url: string,
  ...args: Record<string, unknown>[]
) => {
  try {
    const response: AxiosResponse = await profileApi[method](url, ...args);
    return { data: response?.data, error: null } as IFetchResponse;
  } catch (error: any | unknown) {
    return {
      data: null,
      error: {
        text: error.response?.data,
      },
    } as IFetchResponse;
  }
};
