<template>
  <header :class="{ 'search-bar': getIsHeaderWithSearch }">
    <HeaderWebsiteToggle v-if="!getIsHeaderWithSearch && $vuetify.breakpoint.mdAndUp" />

    <section class="content">
      <div :class="{ 'search-bar': getIsHeaderWithSearch }" class="logo">
        <router-link :to="{ name: 'ConsumersHome' }">
          <img class="img" :src="`/img/${getLogo}`" alt="patient genie" />
        </router-link>
      </div>

      <div v-if="getIsHeaderWithSearch" class="search-container">
        <AppSearchBar />
      </div>

      <nav v-if="isShowNav && !getIsAuthLoading" class="nav-container">
        <button
          :class="{ 'search-bar': getIsHeaderWithSearch }"
          class="drawer-btn"
          data-testid="consumer-header-drawer-btn"
          @click="toggleIsDrawerOpened"
        >
          <img class="menu-icon" alt="menu icon" src="/icons/burger-menu.svg" />
        </button>
      </nav>
      <div v-if="!getIsAuthLoading && !$appConfig.isIntegration" class="signin-links-wrapper">
        <template v-if="!getUser">
          <AppButton
            :to="{ name: 'Login', query: { redirect: $route.path } }"
            height="40"
            class="signin-link signin-link_login"
            data-testid="header-login-link"
          >
            <v-icon color="var(--color-white)">
              {{ mdiAccountCircleOutline }}
            </v-icon>

            Log in
          </AppButton>

          <AppButton
            :to="{ name: 'Signup' }"
            height="40"
            outlined
            class="signin-link signin-link_signup"
            data-testid="header-signup-link"
          >
            Sign up
          </AppButton>
        </template>
        <template v-else>
          <AppButton
            v-if="isUserIncomplete"
            height="40"
            outlined
            class="signin-link signin-link_log-out"
            @click="handleLogOut"
          >
            <v-icon>{{ mdiLogout }}</v-icon>
            Log out
          </AppButton>
          <div class="drawer-controls-wrapper">
            <button
              class="user-drawer-menu"
              data-testid="header-drawer-user-menu-btn"
              @click="toggleIsDrawerOpened"
            >
              <img
                :src="getImageById(getUser.avatar, '80', '80', 'avatarUser')"
                height="40"
                width="40"
                alt=""
              />
            </button>
            <div v-if="!isUserIncomplete" class="user-header-menu">
              <UserHeaderMenu />
            </div>
          </div>
        </template>
      </div>
    </section>
  </header>
</template>

<script lang="ts">
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { mdiAccountCircleOutline, mdiLogout } from "@mdi/js";
import { getImageById } from "@/services/images";
import AppSearchBar from "@/components/AppComponents/AppSearchBar.vue";
import { AppButton } from "pg-ui";
import UserHeaderMenu from "@/components/Header/UserHeaderMenu.vue";
import HeaderWebsiteToggle from "@/components/Header/HeaderWebsiteToggle.vue";

export default Vue.extend({
  name: "ConsumerHeader",
  components: {
    HeaderWebsiteToggle,
    UserHeaderMenu,
    AppSearchBar,
    AppButton,
  },
  data() {
    return {
      mdiAccountCircleOutline,
      mdiLogout,
      integrationURL: process.env.VUE_APP_INTEGRATION_URL,
    };
  },
  mounted() {
    if (this.$route.name) {
      this.setIsHeaderWithSearch(this.$route.name !== "ConsumersHome");
    }
  },
  computed: {
    ...mapGetters({
      getIsHeaderWithSearch: "getIsHeaderWithSearch",
      getIsDrawerOpened: "getIsDrawerOpened",
      getUser: "user/getUser",
      getIsAuthLoading: "user/getIsAuthLoading",
    }),
    getLogo(): string {
      if (process.env.VUE_APP_INTEGRATION === "austin-general") {
        return "austin-general-logo.svg";
      }

      if (this.getIsHeaderWithSearch && this.$vuetify.breakpoint.mdAndDown) {
        return "logo-small-beta.svg";
      }

      if (this.getIsHeaderWithSearch) {
        return "logo-beta.svg";
      }

      return "logo-img.png";
    },
    isUserIncomplete(): boolean {
      return this.getUser && (!this.getUser?.isEmailConfirmed || this.getUser?.onboarding?.length);
    },
    isShowNav(): boolean {
      return !this.$appConfig.isIntegration && !this.$appConfig.isEmbed && !this.getUser;
    },
  },
  watch: {
    "$route.name"(value: string) {
      this.setIsHeaderWithSearch(value !== "ConsumersHome");
    },
  },
  methods: {
    ...mapActions(["setIsHeaderWithSearch", "setIsDrawerOpened"]),
    ...mapActions("user", ["setUser", "signOut"]),
    getImageById,
    toggleIsDrawerOpened() {
      this.setIsDrawerOpened(!(this as any).getIsDrawerOpened);
    },
    handleLogOut() {
      this.signOut();
      if (this.$route.name !== "Home") this.$router.push({ name: "Home" });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

header {
  position: sticky;
  top: 0;
  z-index: 5;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 0.08);
  transition: background-color 0.3s ease;

  &.search-bar {
    background-color: var(--color-purple-light);
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1920px;
  height: var(--header-with-search-height);
  margin: 0 auto;
  padding: 0 44px;

  @include breakpoint(small) {
    padding: 0 20px;
  }
}

.logo {
  max-height: 34px;
  margin-right: 24px;

  @include breakpoint(small) {
    & a {
      display: flex;
    }
  }

  &.search-bar {
    @include breakpoint(small) {
      max-width: 100px;
      margin-right: 24px;
    }

    @include breakpoint(xsmall) {
      margin-right: 16px;
    }
  }

  .img {
    height: 34px;
  }
}

.search-container {
  width: 100%;
  max-width: 660px;
  height: 40px;
  margin-right: auto;

  @include breakpoint(large) {
    max-width: unset;
    margin: 0 auto;
  }

  &::v-deep {
    .field {
      height: 40px;

      .v-input__slot {
        height: 40px;
        min-height: 40px;
      }

      .v-input__append-inner {
        margin: 0;
      }
    }
  }
}

.nav-container {
  margin-left: auto;

  .nav-list {
    display: flex;
    gap: 44px;

    @include breakpoint(medium) {
      display: none;
    }
  }

  .nav-item {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--color-grey-primary);
    font-weight: var(--font-weight-bolder);
    white-space: nowrap;

    &:not(:first-child) {
      &::before {
        position: absolute;
        left: -22px;
        display: block;
        width: 1px;
        height: 20px;
        background-color: var(--color-grey-primary);
        content: "";
      }
    }

    a {
      color: inherit;
    }

    .menu-item {
      position: relative;
      margin-right: -22px;
      margin-bottom: -20px;
      margin-left: -22px;
      padding-right: 22px;
      padding-bottom: 20px;
      padding-left: 22px;

      &::before {
        position: absolute;
        bottom: 20px;
        display: none;
        width: calc(100% - 44px); // 22 + 22 - left/right paddings
        height: 2px;
        background-color: var(--color-purple-primary);
        content: "";
      }

      &:hover {
        &::before {
          display: block;
        }
      }
    }
  }
}

.mobile-user-nav-btn {
  display: none;

  @include breakpoint(medium) {
    display: flex;
    width: auto;
    margin-left: 24px;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.drawer-btn {
  display: none;
  text-align: right;

  @media (max-width: 1279px) {
    display: flex;
    width: auto;
    margin-left: 24px;
  }

  .menu-icon {
    height: 20px;
    cursor: pointer;
  }
}

.signin-links-wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-left: 16px;

  @include breakpoint-reverse(medium) {
    margin-left: 24px;
  }

  .signin-link {
    height: 40px;
    border-radius: 8px;

    &_log-out {
      display: none;

      @include breakpoint-reverse(medium) {
        display: block;
      }

      &::v-deep {
        .button-text {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }

    &_login {
      color: var(--color-white);

      &::v-deep {
        .button-text {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }

    &_login,
    &_signup {
      display: none;

      @include breakpoint-reverse(medium) {
        display: flex;
      }
    }
  }
}

.drawer-controls-wrapper {
  .user-header-menu {
    display: none;

    @include breakpoint-reverse(medium) {
      display: block;
    }

    &::v-deep .user-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .user-drawer-menu {
    display: flex;

    @include breakpoint-reverse(medium) {
      display: none;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

.v-menu__content::v-deep.dropdown-menu {
  position: fixed;
  top: var(--header-with-search-height) !important;
  padding: 44px 24px;
  font-weight: var(--font-weight-normal);
  background-color: var(--color-white);
  border-radius: 24px;
  box-shadow: var(--default-box-shadow);

  .dropdown-menu-content {
    display: flex;
    flex-direction: column;
    gap: 16px 24px;

    &.multi-columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    a {
      color: var(--color-grey-primary);

      &:hover {
        color: var(--color-purple-primary);
      }
    }

    .router-link-exact-active {
      color: var(--color-purple-primary);
    }
  }
}
</style>
