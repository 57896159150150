<template>
  <header ref="header">
    <HeaderWebsiteToggle v-if="$vuetify.breakpoint.mdAndUp && showWebsiteToggle" />

    <section class="content">
      <div class="logo">
        <router-link :to="{ name: 'Home' }">
          <img class="img" src="/img/logo-beta.svg" alt="patient genie" />
        </router-link>
      </div>

      <nav class="nav-container">
        <ul class="nav-list" data-testid="header-nav-list">
          <li
            v-for="item in menuItems"
            :key="item.id"
            class="nav-item"
            :class="{
              active: item.to
                ? activeMenuItemId === item.id && $route.path === item.to.path
                : item.isDropdownOpen,
            }"
            @click="activeMenuItemId = item.id"
          >
            <template v-if="item.to">
              <v-icon v-if="item.icon" color="var(--color-grey-primary)">
                {{ item.icon }}
              </v-icon>
              <router-link :to="item.to" class="menu-item">
                {{ item.title }}
              </router-link>
            </template>

            <v-menu
              v-else
              v-model="item.isDropdownOpen"
              transition="slide-y-transition"
              nudge-top="-43"
              :nudge-left="item.dropdownItems.length > 5 ? '100%' : '20%'"
              open-on-hover
              content-class="dropdown-menu"
            >
              <template #activator="{ on, attrs }">
                <v-icon v-if="item.icon" color="var(--color-grey-primary)">
                  {{ item.icon }}
                </v-icon>
                <button
                  v-bind="attrs"
                  v-on="on"
                  class="menu-item"
                  @click="activeMenuItemId = item.id"
                >
                  {{ item.title }}
                </button>
              </template>
              <div
                class="dropdown-menu-content"
                :class="{ 'multi-columns': item.dropdownItems.length > 5 }"
              >
                <router-link
                  v-for="(dropdownItem, i) in item.dropdownItems"
                  :key="i"
                  :to="{ path: dropdownItem.path, hash: dropdownItem.hash }"
                >
                  {{ dropdownItem.title }}
                </router-link>
              </div>
            </v-menu>
          </li>
        </ul>

        <button
          class="drawer-btn"
          data-testid="business-header-drawer-btn"
          @click="toggleIsDrawerOpened"
        >
          <img class="menu-icon" alt="menu icon" src="/icons/burger-menu.svg" />
        </button>
      </nav>
      <div class="action-links-wrapper">
        <AppButton
          :href="`${adminProfileUrl}/login`"
          height="40"
          outlined
          class="action-link action-link_login"
          data-testid="business-header-login-link"
        >
          Log in
        </AppButton>

        <AppButton
          :to="{ path: '/', hash: '#request-demo-section' }"
          height="40"
          class="action-link action-link_demo"
          data-testid="business-header-demo-link"
        >
          Demo

          <v-icon>
            {{ mdiArrowRight }}
          </v-icon>
        </AppButton>
      </div>
    </section>
  </header>
</template>

<script lang="ts">
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { mdiArrowRight } from "@mdi/js";
import throttle from "@/services/throttle";
import { AppButton } from "pg-ui";
import HeaderWebsiteToggle from "@/components/Header/HeaderWebsiteToggle.vue";

export default Vue.extend({
  name: "BusinessHeader",
  components: {
    HeaderWebsiteToggle,
    AppButton,
  },
  data() {
    return {
      mdiArrowRight,
      adminProfileUrl: process.env.VUE_APP_PROFILE_URL,
      activeMenuItemId: null as null | number,
      menuItems: [
        {
          id: 1,
          title: "Practices",
          to: { path: "/providers-business" },
        },
        {
          id: 2,
          title: "Health Systems",
          to: { path: "/health-systems-business" },
        },
        {
          id: 3,
          title: "Employers & Plans",
          to: { path: "/employers-and-plans-business" },
        },
      ],
      headerEl: null as null | HTMLElement,
      scrollThrottle: null as null | any,
      showWebsiteToggle: true,
    };
  },
  computed: {
    ...mapGetters({
      getIsDrawerOpened: "getIsDrawerOpened",
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.headerEl = this.$refs.header as HTMLElement;
      this.scrollThrottle = throttle(this.scrollPageHandler, 100);
      window.addEventListener("scroll", this.scrollThrottle);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollThrottle);
  },
  methods: {
    ...mapActions(["setIsDrawerOpened"]),
    toggleIsDrawerOpened() {
      this.setIsDrawerOpened(!this.getIsDrawerOpened);
    },
    scrollPageHandler() {
      const currentScrollPosition = window.scrollY;

      if (!this.headerEl) return;

      const headerHeight = this.headerEl.offsetHeight;

      this.showWebsiteToggle = currentScrollPosition < headerHeight;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

header {
  position: sticky;
  top: 0;
  z-index: 5;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 0.08);
  transition: background-color 0.3s ease;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1920px;
  height: var(--header-with-search-height);
  margin: 0 auto;
  padding: 0 44px;

  @include breakpoint(small) {
    padding: 0 20px;
  }
}

.logo {
  max-height: 34px;
  margin-right: 24px;

  @include breakpoint(small) {
    & a {
      display: flex;
    }
  }

  .img {
    height: 34px;
  }
}

.nav-container {
  margin-left: auto;

  .nav-list {
    display: flex;
    gap: 44px;

    @include breakpoint(medium) {
      display: none;
    }
  }

  .nav-item {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--color-grey-primary);
    font-weight: var(--font-weight-bolder);
    white-space: nowrap;

    &:not(:first-child) {
      &::before {
        position: absolute;
        left: -22px;
        display: block;
        width: 1px;
        height: 20px;
        background-color: var(--color-grey-primary);
        content: "";
      }
    }

    a {
      color: inherit;
    }

    .menu-item {
      position: relative;
      margin-right: -22px;
      margin-bottom: -20px;
      margin-left: -22px;
      padding-right: 22px;
      padding-bottom: 20px;
      padding-left: 22px;

      &::before {
        position: absolute;
        bottom: 20px;
        display: none;
        width: calc(100% - 44px); // 22 + 22 - left/right paddings
        height: 2px;
        background-color: var(--color-purple-primary);
        content: "";
      }

      &:hover {
        &::before {
          display: block;
        }
      }
    }
  }
}

.drawer-btn {
  display: none;
  text-align: right;

  @media (max-width: 1279px) {
    display: flex;
    width: auto;
    margin-left: 24px;
  }

  .menu-icon {
    height: 20px;
    cursor: pointer;
  }
}

.action-links-wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-left: 16px;

  @include breakpoint-reverse(medium) {
    margin-left: 24px;
  }

  .action-link {
    height: 40px;
    border-radius: 8px;

    &_login {
      &::v-deep {
        .button-text {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }

    &_demo {
      color: var(--color-white);

      &::v-deep {
        .button-text {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }

    &_login,
    &_demo {
      display: none;

      @include breakpoint-reverse(medium) {
        display: flex;
      }
    }
  }
}

.drawer-controls-wrapper {
  .user-header-menu {
    display: none;

    @include breakpoint-reverse(medium) {
      display: block;
    }

    &::v-deep .user-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .user-drawer-menu {
    display: flex;

    @include breakpoint-reverse(medium) {
      display: none;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

.v-menu__content::v-deep.dropdown-menu {
  position: fixed;
  top: var(--header-with-search-height) !important;
  padding: 44px 24px;
  font-weight: var(--font-weight-normal);
  background-color: var(--color-white);
  border-radius: 24px;
  box-shadow: var(--default-box-shadow);

  .dropdown-menu-content {
    display: flex;
    flex-direction: column;
    gap: 16px 24px;

    &.multi-columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    a {
      color: var(--color-grey-primary);

      &:hover {
        color: var(--color-purple-primary);
      }
    }

    .router-link-exact-active {
      color: var(--color-purple-primary);
    }
  }
}
</style>
