<template>
  <AppDialog
    :is-dialog-open="isShowSessionModal"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    max-width="483"
    content-class="the-session-modal"
    @close="handleCloseModal"
  >
    <div class="content">
      <img src="/img/session-modal-img.svg" width="225" height="185" class="image" alt="" />

      <h3 class="dialog-title">Are you still there?</h3>
      <p class="dialog-subtitle">
        We'll close this session in <span class="reset-timer">1 minute</span>
      </p>

      <AppButton height="55" class="reset-btn" @click="handleCloseModal">I'm here</AppButton>
    </div>
  </AppDialog>
</template>

<script setup lang="ts">
import Vue, { onMounted, onUnmounted, ref, watch } from "vue";
import api from "@/api";
import store from "@/store";
import { useRouter, useRoute } from "vue-router/composables";
import throttle from "@/services/throttle";
import { BroadcastChannel } from "broadcast-channel";
import { AppDialog, AppButton } from "pg-ui";
import { getCookie } from "@/services/cookieService";
import { getAuth } from "firebase/auth";

const ONE_MIN = 60 * 1000;
const MAX_IDLE_TIME_MIN = Vue.prototype.$appConfig.sessionExpirationTimeSeconds * 1000;

const USER_ACTIVITY_EVENTS = ["mousemove", "keydown", "mousedown", "touchstart", "scroll"];

const router = useRouter();
const route = useRoute();

const broadcastChannel = new BroadcastChannel("sessionModalChannel");

const emit = defineEmits<{ fetchSessionToken: [] }>();

const isIdle = ref(false);
const isShowSessionModal = ref(false);
const idleTimerTimeoutId = ref<ReturnType<typeof setTimeout> | null>(null);
const resetTimerTimeoutId = ref<ReturnType<typeof setTimeout> | null>(null);
const lastActiveTime = ref(Number(localStorage.getItem("lastActive"))) || Date.now();
const setLastActiveTimeThrottle = ref<(() => void) | null>(null);

broadcastChannel.postMessage({ type: "updateLastActive", lastActive: lastActiveTime.value });

watch(isIdle, (value) => {
  if (value && !document.hidden) {
    handleIdle();
  }
});

onMounted(() => {
  setTimeout(extendSession, 0);
  setLastActiveTimeThrottle.value = throttle(setLastActiveTime, 100);

  USER_ACTIVITY_EVENTS.forEach((event) => {
    document.addEventListener(event, setLastActiveTimeThrottle.value!);
  });

  document.addEventListener("visibilitychange", handleVisibilityChange);

  broadcastChannel.onmessage = handleBroadcastMessage;
});

onUnmounted(() => {
  clearTimeout(resetTimerTimeoutId.value!);
  clearTimeout(idleTimerTimeoutId.value!);

  USER_ACTIVITY_EVENTS.forEach((event) => {
    document.removeEventListener(event, setLastActiveTimeThrottle.value!);
  });

  document.removeEventListener("visibilitychange", handleVisibilityChange);
});

function resetIdle() {
  isIdle.value = false;
  clearTimeout(idleTimerTimeoutId.value!);
  idleTimerTimeoutId.value = setTimeout(() => (isIdle.value = true), MAX_IDLE_TIME_MIN);
}

function handleIdle() {
  isShowSessionModal.value = true;
  broadcastChannel.postMessage("openSessionModal");

  resetTimerTimeoutId.value = setTimeout(() => {
    isShowSessionModal.value = false;
    broadcastChannel.postMessage("sessionStateReset");
    broadcastChannel.postMessage("closeSessionModal");
    resetIdle();
    resetSessionData();

    if (route.meta?.isEndSessionRedirect && route.name !== "Home") {
      router.push({ name: "Home" });
    }
  }, ONE_MIN);
}

function resetSessionData() {
  const { currentUser } = getAuth();
  if (currentUser) {
    store.dispatch("user/signOut");
  }

  emit("fetchSessionToken");
  localStorage.setItem("authToken", "");
  localStorage.setItem("compare", "");
  document.cookie = "token=; path=/";
}

function handleVisibilityChange() {
  if (isShowSessionModal.value) return;

  if (!document.hidden) {
    const idleTime = Date.now() - lastActiveTime.value;

    if (idleTime >= MAX_IDLE_TIME_MIN) {
      resetIdle();
      resetSessionData();

      broadcastChannel.postMessage("sessionStateReset");

      if (route.meta?.isEndSessionRedirect && route.name !== "Home") {
        router.push({ name: "Home" });
      }
    }
  }
}

async function extendSession() {
  if (!localStorage.getItem("authToken") && !getCookie("token")) {
    emit("fetchSessionToken");

    return;
  }

  const { error } = await api.session.extendSession();

  if (error) {
    resetSessionData();

    if (route.meta?.isEndSessionRedirect && route.name !== "Home") {
      router.push({ name: "Home" });
      return;
    }

    if (route.name !== "Home") {
      window.location.reload();
    }
  }
}

const throttledExtendSession = throttle(extendSession, 5000);

function setLastActiveTime() {
  resetIdle();

  if (isShowSessionModal.value) return;

  lastActiveTime.value = Date.now();
  localStorage.setItem("lastActive", lastActiveTime.value.toString());
  broadcastChannel.postMessage({ type: "updateLastActive", lastActive: lastActiveTime.value });
  throttledExtendSession();
}

function handleBroadcastMessage(event: any) {
  if (event === "sessionStateReset") {
    resetIdle();

    if (route.meta?.isEndSessionRedirect && route.name !== "Home") {
      router.push({ name: "Home" });
    }
  }

  if (event === "closeSessionModal") {
    resetIdle();
    isShowSessionModal.value = false;
    clearTimeout(resetTimerTimeoutId.value!);
  }

  if (event === "openSessionModal") {
    isShowSessionModal.value = true;
  }

  if (event.type === "updateLastActive") {
    lastActiveTime.value = event.lastActive;
  }
}

function handleCloseModal() {
  isShowSessionModal.value = false;
  broadcastChannel.postMessage("closeSessionModal");
  clearTimeout(resetTimerTimeoutId.value!);
  resetIdle();
}
</script>

<style lang="scss" scoped>
.the-session-modal {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 44px;

    .image {
      margin-bottom: 44px;
    }

    .dialog-title {
      margin-bottom: 24px;
      font-size: var(--font-bigger);
    }

    .dialog-subtitle {
      margin-bottom: 44px;

      .reset-timer {
        color: var(--color-purple-primary);
        font-weight: var(--font-weight-bolder);
      }
    }

    .reset-btn {
      width: 100%;
      color: var(--color-white);
    }
  }
}
</style>
