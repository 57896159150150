import { callPatientGenieApi } from "@/api/apiHelper";
import { IFetchResponse, IInternetSearchResult } from "@/types";

export const getResults = async (
  keyword: string,
  start: number
): Promise<IFetchResponse<IInternetSearchResult[]>> => {
  return await callPatientGenieApi("get", "/internet-search", {
    params: {
      k: keyword,
      st: start,
    },
  });
};
