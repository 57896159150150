import { IFetchResponse } from "@/types";
import { callPatientGenieApi } from "@/api/apiHelper";
import { ISearchParams } from "@/types/ISearchParams";

export const storeSearchParams = async (
  searchParams: ISearchParams
): Promise<IFetchResponse<string>> => {
  return await callPatientGenieApi("post", "/short-params", searchParams);
};

export const getSearchParams = async (key: string): Promise<IFetchResponse<ISearchParams>> => {
  return await callPatientGenieApi("get", `/short-params/${key}`);
};
