<template>
  <v-menu
    v-model="isMenuOpen"
    content-class="user-header-menu"
    max-width="300"
    offset-y
    left
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <button v-bind="attrs" v-on="on" data-testid="header-user-menu-btn">
        <img
          :src="getImageById(user.avatar, 80, 80, 'avatarUser')"
          height="40"
          width="40"
          class="user-avatar"
          alt=""
        />
      </button>
    </template>

    <div class="menu-content" data-testid="header-user-menu-content">
      <div class="user-info">
        <img
          :src="getImageById(user.avatar, 120, 120, 'avatarUser')"
          height="60"
          width="60"
          class="user-avatar"
          alt=""
        />
        <span class="user-name">{{ `${user.firstName} ${user.lastName}` }}</span>
        <span class="user-email">{{ user.email }}</span>
      </div>
      <div class="user-nav">
        <ul class="user-nav-list">
          <li
            v-for="item in getNavigationItems"
            :key="item.routeName"
            data-testid="header-user-nav-item"
          >
            <router-link :to="{ name: item.routeName }" class="nav-item-link">
              <v-icon>{{ item.icon }}</v-icon>
              <span class="link-title">{{ item.title }}</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="user-logout">
        <button class="log-out-btn" data-testid="header-user-menu-logout-btn" @click="logOut">
          <v-icon color="var(--color-grey-primary)">{{ mdiLogout }}</v-icon>
          <span>Log out</span>
        </button>
      </div>
    </div>
  </v-menu>
</template>

<script setup lang="ts">
import store from "@/store";
import { computed, ref } from "vue";
import { mdiLogout } from "@mdi/js";
import { useRouter } from "vue-router/composables";
import { getImageById } from "@/services/images";

const router = useRouter();
const isMenuOpen = ref(false);
const getNavigationItems = computed(() => store.getters["patientProfile/getNavigationItems"]);
const user = computed(() => store.getters["user/getUser"]);

function logOut() {
  store.dispatch("user/signOut");
  isMenuOpen.value = false;
  router.push({ name: "Home" });
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.v-menu__content::v-deep.user-header-menu {
  padding: 24px;
  border-radius: 24px;
  box-shadow: var(--default-box-shadow);

  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--color-purple-light);

    .user-avatar {
      width: 60px;
      height: 60px;
      margin-bottom: 8px;
      border-radius: 50%;
    }

    .user-name {
      font-weight: var(--font-weight-bolder);
    }

    .user-email {
      font-size: var(--font-small);
    }
  }

  .user-nav {
    padding: 16px 0;
    border-bottom: 1px solid var(--color-purple-light);

    .user-nav-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 0;
      padding: 0;

      .nav-item-link {
        display: grid;
        grid-template-columns: 20px auto;
        gap: 16px;
        align-items: center;
        color: var(--color-grey-primary);

        .v-icon {
          color: var(--color-grey-primary);
        }

        &.router-link-exact-active {
          color: var(--color-purple-primary);

          .v-icon {
            color: var(--color-purple-primary);
          }
        }
      }
    }
  }

  .user-logout {
    padding-top: 16px;

    .log-out-btn {
      display: grid;
      grid-template-columns: 20px auto;
      gap: 16px;
      align-items: center;
    }
  }
}
</style>
