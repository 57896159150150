import api from "@/api";
import { NavigationGuardNext, Route } from "vue-router";

export async function handleStartConversationEmbed(
  to: Route,
  from: Route,
  next: NavigationGuardNext
) {
  const urlParams = new URLSearchParams(window.location.search);
  const isStartNewConversation = urlParams.get("cmd") === "startConversation";

  if (process.env.VUE_APP_INTEGRATION_URL && !isStartNewConversation) {
    window.location.replace(process.env.VUE_APP_INTEGRATION_URL);
  }

  if (!isStartNewConversation) return;

  const keyword = urlParams.get("keyword");
  const path = urlParams.get("path");

  if (!keyword) return;

  const { data: tokenData } = await api.session.start();
  document.cookie = `token=${tokenData.token}; path=/; SameSite=None; Secure`;

  const { data, error } = await api.conversation.start({
    keyword: keyword,
    value: keyword,
    path: path || "",
  });

  if (error) return;

  next({
    name: "Conversation",
    params: { id: data.id },
  });
}
