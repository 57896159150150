<template>
  <section class="reason-search">
    <template v-if="!isFieldFocusedOnMobile">
      <div
        id="autocomplete-overlay"
        :class="{ focused: isFieldFocused }"
        class="autocomplete-overlay"
      />

      <AppSearchBarAutocomplete
        :value.sync="inputValue"
        :results="autocompleteResults"
        class="autocomplete"
        @focus="handleFocusOnDesktop"
        @blur="handleBlurOnDesktop"
        @fetchAutocompleteResults="fetchAutocompleteResults"
        @startNewSession="startNewSession"
      />
    </template>

    <v-dialog
      v-else
      v-model="isFieldFocused"
      overlay-color="var(--color-white)"
      overlay-opacity="1"
      fullscreen
      transition=""
    >
      <AppSearchBarAutocomplete
        is-focused
        :value.sync="inputValue"
        :results="autocompleteResults"
        class="autocomplete"
        @focus="handleFocusOnMobile"
        @blur="handleBlurOnMobile"
        @fetchAutocompleteResults="fetchAutocompleteResults"
        @startNewSession="startNewSession"
      />
    </v-dialog>
  </section>
</template>

<script lang="ts">
import Vue from "vue";
import api from "@/api";
import { mapGetters, mapActions } from "vuex";
import { IAutocompleteResult } from "@/types";
import AppSearchBarAutocomplete from "@/components/AppComponents/AppSearchBarAutocomplete.vue";

export default Vue.extend({
  name: "AppSearchBar",
  components: { AppSearchBarAutocomplete },
  props: {
    initialEmpty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isFieldFocused: false,
      searchTimer: null as null | ReturnType<typeof setTimeout>,
      searchTimeout: 150,
      fetchedWords: [] as IAutocompleteResult[],
    };
  },
  computed: {
    ...mapGetters(["getSearchReason", "getAutocompleteResults"]),
    autocompleteResults: {
      get(): IAutocompleteResult[] {
        return (this as any).getAutocompleteResults;
      },
      set(value: IAutocompleteResult[]) {
        this.setAutocompleteResults(value);
      },
    },
    inputValue: {
      get(): string {
        return this.initialEmpty ? "" : (this as any).getSearchReason;
      },
      set(value: string) {
        if (!value.trim()) {
          this.fetchAutocompleteResults();
        }

        this.setSearchReason(value);
      },
    },
    isFieldFocusedOnMobile(): boolean {
      return this.isFieldFocused && this.$vuetify.breakpoint.xs;
    },
  },
  async mounted() {
    if (!this.autocompleteResults.length) this.fetchAutocompleteResults();
  },
  methods: {
    ...mapActions(["setSearchReason", "setAutocompleteResults"]),
    async startNewSession(result: IAutocompleteResult): Promise<void> {
      if (this.isFieldFocusedOnMobile) {
        this.handleBlurOnMobile();
      } else {
        this.handleBlurOnDesktop();
      }

      this.$emit("startNewSession");
      await this.startNewTriageSession(result);
    },
    async startNewTriageSession(result: IAutocompleteResult): Promise<void> {
      if (!this.inputValue) return;

      const { data, error } = await api.conversation.start({
        keyword: result?.keyword || this.inputValue,
        value: result?.value || this.inputValue,
        path: result?.path || "",
      });

      if (error) return;

      if (this.isFieldFocusedOnMobile) {
        this.handleBlurOnMobile();
      } else {
        this.handleBlurOnDesktop();
      }

      this.$router.push({
        name: "Conversation",
        params: { id: data.id },
      });
    },
    fetchAutocompleteResults(): void {
      if (this.searchTimer) clearTimeout(this.searchTimer);

      this.searchTimer = setTimeout(async () => {
        await Promise.all([await this.fetchWords(), this.fetchSymptoms()]);

        this.searchTimer = null;
      }, this.searchTimeout);
    },
    async fetchWords() {
      const { data } = await api.autocomplete.getWords(this.inputValue);

      if (data) {
        this.fetchedWords = data.slice(0, 7);
      }
    },
    async fetchSymptoms() {
      const { data } = await api.autocomplete.getSymptoms(this.inputValue);

      if (!data) {
        this.autocompleteResults = [...this.fetchedWords];
        return;
      }

      // check for case if symptoms already in the array
      if (this.autocompleteResults.some((word) => word.path === "symptom")) {
        this.autocompleteResults = this.autocompleteResults.filter(
          (word) => word.path !== "symptom"
        );
      }

      this.autocompleteResults = [...data.slice(0, 3), ...this.fetchedWords];
    },
    handleFocusOnMobile() {
      // prevent page scroll
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
      this.isFieldFocused = true;
    },
    handleBlurOnMobile() {
      document.documentElement.style.overflow = "";
      document.body.style.overflow = "";
      this.isFieldFocused = false;
    },
    handleFocusOnDesktop() {
      this.isFieldFocused = true;
      this.$emit("focus");
    },
    handleBlurOnDesktop() {
      this.isFieldFocused = false;
      this.$emit("blur");
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.reason-search {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  min-height: 40px;
  margin: 0 auto;
}

.search-bar {
  .autocomplete::v-deep {
    .field {
      height: 40px;
      padding: 8px 75px 8px 24px;

      @include breakpoint(xsmall) {
        padding: 8px 44px 8px 24px;
      }
    }

    .field-button {
      top: 10px;

      &.clear {
        right: 48px;
        margin-right: 5px;
      }
    }
  }
}

.error-message {
  display: block;
  padding: 5px 0;
  color: var(--color-peach);
}

.autocomplete-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: none;
  background-color: var(--color-grey-secondary);
  opacity: 0;
  will-change: opacity;

  &.focused {
    display: block;
    opacity: 0.6;
  }
}

.v-dialog__content::v-deep {
  background-color: var(--color-white);

  .autocomplete {
    position: fixed;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background-color: var(--color-white);
    border-radius: 0;

    .field {
      padding-left: 42px;
      border-bottom: var(--border-panel-purple);
      border-radius: 0;
    }

    .field-button.clear {
      right: 14px;
    }

    .result:last-child {
      border-radius: 0;
    }
  }

  @include breakpoint(small) {
    &.fullscreen {
      min-height: 60px;

      .autocomplete::v-deep {
        .field {
          height: 60px;
        }

        .field-button {
          top: 20px;
        }
      }
    }
  }
}
</style>
