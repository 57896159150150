import { Commit } from "vuex";
import { IProvidersRequest, IProviderShort } from "@/types/IProviders";
import api from "@/api";

export interface IProvidersState {
  controller: AbortController;

  providersSearchRequest: IProvidersRequest;
  similarProvidersSearchRequest: IProvidersRequest;
  defaultProvidersSearchRequest: IProvidersRequest;

  providersList: IProviderShort[];
  providersListLoading: boolean;
  providersCountTotal: string;
  providersFacetsOrgs: string[];
  providersFacetsSpecialties: string[];
  providersCanViewMore: boolean;
}

export default {
  namespaced: true,
  state: (): IProvidersState => ({
    controller: new AbortController(),

    defaultProvidersSearchRequest: {
      path: "",
      value: "",
      params: {
        patientGender: "",
        patientAge: 0,
        latitude: 0,
        longitude: 0,
        page: 0,
        pageSize: 10,
        sortBy: "",
        assumptions: {
          taxonomyCodes: [],
          specialties: [],
          cptCodes: [],
          procedures: [],
          diseases: [],
          icdCodes: [],
        },
      },
      filters: {
        distance: 25,
        gender: {
          female: false,
          male: false,
        },
        insuranceCompany: "",
        insurancePlanIds: [],
        doctorsOnly: false,
        scheduleOnly: false,
        language: "",
        telehealth: false,
        experience: [],
        specialty: "",
        degree: "",
        taxonomy: "",
        unitId: "",
        groupId: "",
        organization: "",
        hasPrice: false,
      },
    },
    providersSearchRequest: {
      path: "",
      value: "",
      params: {
        patientGender: "",
        patientAge: 0,
        latitude: 0,
        longitude: 0,
        page: 0,
        pageSize: 10,
        sortBy: "",
        assumptions: {
          taxonomyCodes: [],
          specialties: [],
          cptCodes: [],
          procedures: [],
          diseases: [],
          icdCodes: [],
        },
      },
      filters: {
        distance: 25,
        gender: {
          female: false,
          male: false,
        },
        insuranceCompany: "",
        insurancePlanIds: [],
        doctorsOnly: false,
        scheduleOnly: false,
        language: "",
        telehealth: false,
        experience: [],
        specialty: "",
        degree: "",
        taxonomy: "",
        unitId: "",
        groupId: "",
        organization: "",
        hasPrice: false,
      },
    },
    similarProvidersSearchRequest: {
      path: "similar",
      value: "",
      params: {
        patientGender: "",
        patientAge: 0,
        latitude: 0,
        longitude: 0,
        page: 0,
        pageSize: 5,
        sortBy: "",
        assumptions: {
          taxonomyCodes: [],
          specialties: [],
          cptCodes: [],
          procedures: [],
          diseases: [],
          icdCodes: [],
        },
      },
      filters: {
        distance: 25,
        gender: {
          female: false,
          male: false,
        },
        insuranceCompany: "",
        insurancePlanIds: [],
        doctorsOnly: false,
        scheduleOnly: false,
        language: "",
        telehealth: false,
        experience: [],
        specialty: "",
        degree: "",
        taxonomy: "",
        unitId: "",
        groupId: "",
        organization: "",
        hasPrice: false,
      },
    },

    providersList: [],
    providersListLoading: false,
    providersCountTotal: "0",
    providersFacetsOrgs: [],
    providersFacetsSpecialties: [],
    providersCanViewMore: false,
  }),
  getters: {
    getProvidersSearchRequest: (state: IProvidersState): IProvidersRequest => {
      return state.providersSearchRequest;
    },
    getSimilarProvidersSearchRequest: (state: IProvidersState): IProvidersRequest => {
      return state.similarProvidersSearchRequest;
    },
    getDefaultProvidersSearchRequest: (state: IProvidersState): IProvidersRequest => {
      return state.defaultProvidersSearchRequest;
    },
    getProvidersList: (state: IProvidersState): IProviderShort[] => {
      return state.providersList;
    },
    getProvidersListLoading: (state: IProvidersState): boolean => {
      return state.providersListLoading;
    },
    getProvidersCountTotal: (state: IProvidersState): string => {
      return state.providersCountTotal;
    },
    getProvidersFacetsOrgs: (state: IProvidersState): string[] => {
      return state.providersFacetsOrgs;
    },
    getProvidersFacetsSpecialties: (state: IProvidersState): string[] => {
      return state.providersFacetsSpecialties;
    },
    getProvidersCanViewMore: (state: IProvidersState): boolean => {
      return state.providersCanViewMore;
    },
  },
  mutations: {
    REINIT_CONTROLLER: (state: IProvidersState) => {
      state.controller = new AbortController();
    },
    SET_PROVIDERS_SEARCH_REQUEST: (state: IProvidersState, searchRequest: IProvidersRequest) => {
      state.providersSearchRequest = JSON.parse(JSON.stringify(searchRequest));
    },
    SET_SIMILAR_PROVIDERS_SEARCH_REQUEST: (
      state: IProvidersState,
      searchRequest: IProvidersRequest
    ) => {
      state.similarProvidersSearchRequest = searchRequest;
    },
    SET_PROVIDERS_LIST: (state: IProvidersState, providersList: IProviderShort[]) => {
      state.providersList = providersList;
    },
    SET_PROVIDERS_LIST_LOADING: (state: IProvidersState, loading: boolean) => {
      state.providersListLoading = loading;
    },
    SET_PROVIDERS_COUNT_TOTAL: (state: IProvidersState, count: string) => {
      state.providersCountTotal = count;
    },
    SET_PROVIDERS_FACETS_ORGS: (state: IProvidersState, facets: string[]) => {
      state.providersFacetsOrgs = facets;
    },
    SET_PROVIDERS_FACETS_SPECIALTIES: (state: IProvidersState, facets: string[]) => {
      state.providersFacetsSpecialties = facets;
    },
    SET_PROVIDERS_CAN_VIEW_MORE: (state: IProvidersState, canViewMore: boolean) => {
      state.providersCanViewMore = canViewMore;
    },
  },
  actions: {
    setProvidersSearchRequest: (
      { commit }: { commit: Commit },
      searchRequest: IProvidersRequest
    ) => {
      commit("SET_PROVIDERS_SEARCH_REQUEST", searchRequest);
    },
    setSimilarProvidersSearchRequest: (
      { commit }: { commit: Commit },
      searchRequest: IProvidersRequest
    ) => {
      commit("SET_SIMILAR_PROVIDERS_SEARCH_REQUEST", searchRequest);
    },
    setProvidersList: ({ commit }: { commit: Commit }, providersList: IProviderShort[]) => {
      commit("SET_PROVIDERS_COUNT_TOTAL", providersList.length.toString());
      commit("SET_PROVIDERS_LIST", providersList);
    },
    fetchProvidersList: async ({ commit, state }: { commit: Commit; state: IProvidersState }) => {
      if (state.providersListLoading) {
        state.controller.abort();
        commit("REINIT_CONTROLLER");
      }

      commit("SET_PROVIDERS_LIST_LOADING", true);
      const { data } = await api.provider.getList(state.providersSearchRequest, state.controller);

      if (data) {
        let providersCountTotal = new Intl.NumberFormat("en-US").format(data.total);
        if (data.total >= 10000) providersCountTotal += "+";

        commit("SET_PROVIDERS_COUNT_TOTAL", providersCountTotal);

        if (data.providers) {
          commit("SET_PROVIDERS_LIST", [...state.providersList, ...data.providers]);
        }

        commit("SET_PROVIDERS_FACETS_ORGS", data.facets.organizations);
        commit("SET_PROVIDERS_FACETS_SPECIALTIES", data.facets.specialties);
        commit(
          "SET_PROVIDERS_CAN_VIEW_MORE",
          data.providers?.length === state.providersSearchRequest.params.pageSize
        );
      }

      commit("SET_PROVIDERS_LIST_LOADING", false);
    },
    fetchSimilarProvidersList: async ({
      commit,
      state,
    }: {
      commit: Commit;
      state: IProvidersState;
    }) => {
      commit("SET_PROVIDERS_LIST_LOADING", true);
      const { data } = await api.provider.getList(state.similarProvidersSearchRequest);

      if (data) {
        let providersCountTotal = new Intl.NumberFormat("en-US").format(data.total);
        if (data.total >= 10000) providersCountTotal += "+";

        commit("SET_PROVIDERS_COUNT_TOTAL", providersCountTotal);

        if (data.providers) {
          commit("SET_PROVIDERS_LIST", [...state.providersList, ...data.providers]);
        }

        commit(
          "SET_PROVIDERS_CAN_VIEW_MORE",
          data.providers?.length === state.similarProvidersSearchRequest.params.pageSize
        );
      }

      commit("SET_PROVIDERS_LIST_LOADING", false);
    },
  },
};
