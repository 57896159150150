import { Commit } from "vuex";
import { IOrganizationRequest, IOrganizationShort } from "@/types/IOrganizations";
import api from "@/api";

export interface IOrganizationsState {
  controller: AbortController;

  organizationSearchRequest: IOrganizationRequest;
  similarOrganizationSearchRequest: IOrganizationRequest;
  defaultOrganizationSearchRequest: IOrganizationRequest;

  organizationsList: IOrganizationShort[];
  organizationsListLoading: boolean;
  organizationsCountTotal: string;
  organizationsFacetsOrgs: string[];
  organizationsFacetsSpecialties: string[];
  organizationsCanViewMore: boolean;
}

export default {
  namespaced: true,
  state: (): IOrganizationsState => ({
    controller: new AbortController(),

    defaultOrganizationSearchRequest: {
      path: "organization",
      value: "",
      params: {
        latitude: 0,
        longitude: 0,
        page: 0,
        pageSize: 10,
        sortBy: "distance",
      },
      filters: {
        distance: 25,
        telehealth: false,
        specialties: [],
        type: [],
        unit: [],
      },
    },
    organizationSearchRequest: {
      path: "organization",
      value: "",
      params: {
        latitude: 0,
        longitude: 0,
        page: 0,
        pageSize: 10,
        sortBy: "distance",
      },
      filters: {
        distance: 25,
        telehealth: false,
        specialties: [],
        type: [],
        unit: [],
      },
    },
    similarOrganizationSearchRequest: {
      path: "similar_organizations",
      value: "",
      params: {
        latitude: 0,
        longitude: 0,
        page: 0,
        pageSize: 5,
        sortBy: "",
      },
      filters: {
        distance: 25,
        telehealth: false,
        specialties: [],
        type: [],
        unit: [],
      },
    },

    organizationsList: [],
    organizationsListLoading: false,
    organizationsCountTotal: "0",
    organizationsFacetsOrgs: [],
    organizationsFacetsSpecialties: [],
    organizationsCanViewMore: false,
  }),
  getters: {
    getOrganizationSearchRequest: (state: IOrganizationsState): IOrganizationRequest => {
      return state.organizationSearchRequest;
    },
    getSimilarOrganizationSearchRequest: (state: IOrganizationsState): IOrganizationRequest => {
      return state.similarOrganizationSearchRequest;
    },
    getDefaultOrganizationSearchRequest: (state: IOrganizationsState): IOrganizationRequest => {
      return state.defaultOrganizationSearchRequest;
    },
    getOrganizationsList: (state: IOrganizationsState): IOrganizationShort[] => {
      return state.organizationsList;
    },
    getOrganizationsListLoading: (state: IOrganizationsState): boolean => {
      return state.organizationsListLoading;
    },
    getOrganizationsCountTotal: (state: IOrganizationsState): string => {
      return state.organizationsCountTotal;
    },
    getOrganizationsFacetsOrgs: (state: IOrganizationsState): string[] => {
      return state.organizationsFacetsOrgs;
    },
    getOrganizationsFacetsSpecialties: (state: IOrganizationsState): string[] => {
      return state.organizationsFacetsSpecialties;
    },
    getOrganizationsCanViewMore: (state: IOrganizationsState): boolean => {
      return state.organizationsCanViewMore;
    },
  },
  mutations: {
    REINIT_CONTROLLER: (state: IOrganizationsState) => {
      state.controller = new AbortController();
    },
    SET_ORGANIZATION_SEARCH_REQUEST: (
      state: IOrganizationsState,
      searchRequest: IOrganizationRequest
    ) => {
      state.organizationSearchRequest = JSON.parse(JSON.stringify(searchRequest));
    },
    SET_SIMILAR_ORGANIZATION_SEARCH_REQUEST: (
      state: IOrganizationsState,
      searchRequest: IOrganizationRequest
    ) => {
      state.similarOrganizationSearchRequest = searchRequest;
    },
    SET_ORGANIZATIONS_LIST: (
      state: IOrganizationsState,
      organizationsList: IOrganizationShort[]
    ) => {
      state.organizationsList = organizationsList;
    },
    SET_ORGANIZATIONS_LIST_LOADING: (state: IOrganizationsState, loading: boolean) => {
      state.organizationsListLoading = loading;
    },
    SET_ORGANIZATIONS_COUNT_TOTAL: (state: IOrganizationsState, count: string) => {
      state.organizationsCountTotal = count;
    },
    SET_ORGANIZATIONS_FACETS_ORGS: (state: IOrganizationsState, facets: string[]) => {
      state.organizationsFacetsOrgs = facets;
    },
    SET_ORGANIZATIONS_FACETS_SPECIALTIES: (state: IOrganizationsState, facets: string[]) => {
      state.organizationsFacetsSpecialties = facets;
    },
    SET_ORGANIZATIONS_CAN_VIEW_MORE: (state: IOrganizationsState, canViewMore: boolean) => {
      state.organizationsCanViewMore = canViewMore;
    },
  },
  actions: {
    setOrganizationSearchRequest: (
      { commit }: { commit: Commit },
      searchRequest: IOrganizationRequest
    ) => {
      commit("SET_ORGANIZATION_SEARCH_REQUEST", searchRequest);
    },
    setSimilarOrganizationSearchRequest: (
      { commit }: { commit: Commit },
      searchRequest: IOrganizationRequest
    ) => {
      commit("SET_SIMILAR_ORGANIZATION_SEARCH_REQUEST", searchRequest);
    },
    setOrganizationsList: (
      { commit }: { commit: Commit },
      organizationsList: IOrganizationShort[]
    ) => {
      commit("SET_ORGANIZATIONS_LIST", organizationsList);
    },
    fetchOrganizationsList: async ({
      commit,
      state,
    }: {
      commit: Commit;
      state: IOrganizationsState;
    }) => {
      if (state.organizationsListLoading) {
        state.controller.abort();
        commit("REINIT_CONTROLLER");
      }

      commit("SET_ORGANIZATIONS_LIST_LOADING", true);
      const { data } = await api.organizations.getOrgSearchResults(
        state.organizationSearchRequest,
        state.controller
      );

      if (data) {
        let organizationsCountTotal = new Intl.NumberFormat("en-US").format(data.total);
        if (data.total >= 10000) organizationsCountTotal += "+";

        commit("SET_ORGANIZATIONS_COUNT_TOTAL", organizationsCountTotal);

        if (data.organizations) {
          commit("SET_ORGANIZATIONS_LIST", [...state.organizationsList, ...data.organizations]);
        }

        commit("SET_ORGANIZATIONS_FACETS_ORGS", data.facets.organizations);
        commit("SET_ORGANIZATIONS_FACETS_SPECIALTIES", data.facets.specialties);
        commit(
          "SET_ORGANIZATIONS_CAN_VIEW_MORE",
          data.organizations?.length === state.organizationSearchRequest.params.pageSize
        );
      }

      commit("SET_ORGANIZATIONS_LIST_LOADING", false);
    },
    fetchSimilarOrganizationsList: async ({
      commit,
      state,
    }: {
      commit: Commit;
      state: IOrganizationsState;
    }) => {
      commit("SET_ORGANIZATIONS_LIST_LOADING", true);
      const { data } = await api.organizations.getOrgSearchResults(
        state.similarOrganizationSearchRequest
      );
      commit("SET_ORGANIZATIONS_LIST_LOADING", false);

      if (data) {
        let organizationsCountTotal = new Intl.NumberFormat("en-US").format(data.total);
        if (data.total >= 10000) organizationsCountTotal += "+";

        commit("SET_ORGANIZATIONS_COUNT_TOTAL", organizationsCountTotal);

        if (data.organizations) {
          commit("SET_ORGANIZATIONS_LIST", [...state.organizationsList, ...data.organizations]);
        }

        commit(
          "SET_ORGANIZATIONS_CAN_VIEW_MORE",
          data.organizations?.length === state.similarOrganizationSearchRequest.params.pageSize
        );
      }
    },
  },
};
