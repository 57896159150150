import { IUser } from "@/types/IUser";
import { ActionContext, Commit } from "vuex";
import { getAuth, signOut } from "firebase/auth";

export interface IUserState {
  user: IUser | null;
  isAuthLoading: boolean;
}

export default {
  namespaced: true,
  state: (): IUserState => ({
    user: null,
    isAuthLoading: false,
  }),
  getters: {
    getUser: (state: IUserState) => {
      return state.user;
    },
    getIsAuthLoading: (state: IUserState) => {
      return state.isAuthLoading;
    },
  },
  mutations: {
    SET_USER(state: IUserState, user: IUser) {
      state.user = user;
    },
    SET_IS_AUTH_LOADING(state: IUserState, isAuthLoading: boolean) {
      state.isAuthLoading = isAuthLoading;
    },
  },
  actions: {
    setUser({ commit }: { commit: Commit }, user: IUser) {
      commit("SET_USER", user);
    },
    setIsAuthLoading({ commit }: { commit: Commit }, isAuthLoading: boolean) {
      commit("SET_IS_AUTH_LOADING", isAuthLoading);
    },
    signOut({ commit, dispatch }: ActionContext<IUserState, any>) {
      localStorage.setItem("authToken", "");
      localStorage.setItem("compare", "");
      document.cookie = "token=; path=/";

      signOut(getAuth());

      commit("SET_USER", null);
      dispatch("compareProviders/clearProvidersToCompare", null, { root: true });
    },
  },
};
