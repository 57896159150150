<template>
  <button class="app-more-btn" @click="toggleViewMore">
    <slot />
    <v-icon v-if="showIcon" color="var(--color-blue-primary)">
      {{ isShowMore ? mdiChevronUp : mdiChevronDown }}
    </v-icon>
  </button>
</template>

<script>
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";

export default {
  name: "AppMoreButton",
  props: {
    isShowMore: {
      type: Boolean,
      required: false,
      default: false,
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      mdiChevronUp,
      mdiChevronDown,
    };
  },
  methods: {
    toggleViewMore() {
      this.$emit("update:isShowMore", !this.isShowMore);
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.app-more-btn {
  color: var(--color-blue-primary);
  font-weight: var(--font-weight-bolder);
}
</style>
