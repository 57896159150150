import { IPatientProfileNavigationItem } from "@/types/IPatientProfileNavigation";
import { Commit } from "vuex";
import { mdiAccount, mdiHistory, mdiHeart } from "@mdi/js";

interface IPatientProfileState {
  navigationItems: IPatientProfileNavigationItem[];
  isNavMinified: boolean;
}

export default {
  namespaced: true,
  state: (): IPatientProfileState => ({
    navigationItems: [
      {
        icon: mdiAccount,
        title: "Profile",
        routeName: "PatientProfileInfo",
        isActive: false,
      },
      {
        icon: mdiHistory,
        title: "History",
        routeName: "PatientProfileHistoryAndJourney",
        isActive: false,
      },
      {
        icon: mdiHeart,
        title: "Saved",
        routeName: "PatientProfileSavedProviders",
        isActive: false,
      },
      // {
      //   icon: mdiCogOutline,
      //   title: "Settings",
      //   routeName: "PatientProfileSettings",
      //   isActive: false,
      // },
    ],

    isNavMinified: false,
  }),
  getters: {
    getNavigationItems: (state: IPatientProfileState): IPatientProfileNavigationItem[] => {
      return state.navigationItems;
    },
    getIsNavMinified: (state: IPatientProfileState): boolean => {
      return state.isNavMinified;
    },
  },
  mutations: {
    SET_IS_NAV_MINIFIED(state: IPatientProfileState, isMinified: boolean) {
      state.isNavMinified = isMinified;
    },
    SET_ACTIVE_NAV_ITEM(state: IPatientProfileState, routeName: string) {
      state.navigationItems = state.navigationItems.map((item) => {
        item.isActive = item.routeName === routeName;
        return item;
      });
    },
  },
  actions: {
    setIsNavMinified({ commit }: { commit: Commit }, isMinified: boolean) {
      commit("SET_IS_NAV_MINIFIED", isMinified);
    },
    setActiveNavItem({ commit }: { commit: Commit }, routeName: string) {
      commit("SET_ACTIVE_NAV_ITEM", routeName);
    },
  },
};
