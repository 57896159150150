<template>
  <article class="progress-bar">
    <div
      :style="{
        width: `${value}%`,
        backgroundColor: fillColor,
      }"
      class="progress"
    />
    <div class="total" />
  </article>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "AppProgressBar",
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    fillColor: {
      type: String,
      required: false,
      default: "#828282",
    },
  },
});
</script>

<style lang="scss" scoped>
.progress-bar {
  .total {
    width: 100%;
    height: 1px;
    overflow: hidden;
    background-color: var(--color-white-secondary);
  }

  .progress {
    display: block;
    float: left;
    height: 2px;
    background-color: var(--color-purple-light);
    transition: all 0.7s ease;
  }
}
</style>
