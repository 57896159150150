<template>
  <div
    v-if="providersToCompare.length && route.name !== 'CompareProviders'"
    class="compare-providers-widget"
  >
    <div class="content">
      <div class="providers-list">
        <CompareProvidersWidgetProviderChip
          v-for="provider in providersToCompare"
          :key="provider.npi"
          :provider="provider"
        />

        <CompareProvidersWidgetEmptySlot
          v-for="i in getMaxProvidersCountToCompare - providersToCompare.length"
          :key="i"
        />
      </div>

      <div class="actions-wrapper">
        <AppButton
          :to="{ name: 'CompareProviders' }"
          height="40"
          :disabled="providersToCompare.length === 1"
          class="compare-btn"
        >
          Compare
        </AppButton>
        <AppButton
          height="40"
          text
          color="var(--color-grey-primary)"
          class="clear-btn"
          @click="clearProviders"
        >
          Clear
        </AppButton>
      </div>
    </div>

    <CompareProvidersWidgetBottomSheet />
  </div>
</template>

<script setup lang="ts">
import api from "@/api";
import { computed, onMounted, onUnmounted } from "vue";
import store from "@/store";
import { useRoute } from "vue-router/composables";
import CompareProvidersWidgetProviderChip from "@/components/Compare/CompareProvidersWidgetProviderChip.vue";
import CompareProvidersWidgetEmptySlot from "@/components/Compare/CompareProvidersWidgetEmptySlot.vue";
import CompareProvidersWidgetBottomSheet from "@/components/Compare/CompareProvidersWidgetBottomSheet.vue";
import { AppButton } from "pg-ui";

const route = useRoute();

const providersToCompare = computed(
  () => store.getters["compareProviders/getVisibleProvidersToCompare"]
);
const getMaxProvidersCountToCompare = computed(
  () => store.getters["compareProviders/getMaxProvidersCountToCompare"]
);

function clearProviders() {
  store.dispatch("compareProviders/clearProvidersToCompare");
}

async function getProvidersByNpi(npiList: string[]) {
  const { data } = await api.provider.getProvidersToCompare(npiList);

  return data;
}

async function handleVisibilityChange() {
  if (!document.hidden) {
    if (localStorage.getItem("compare")) {
      const npiList = JSON.parse(localStorage.getItem("compare") as string);

      const isEveryNpiInStore = npiList.every((value: string, index: number) => {
        return value === providersToCompare.value[index]?.npi;
      });

      if (npiList.length === providersToCompare.value?.length && isEveryNpiInStore) {
        return;
      }

      if (!npiList.length) {
        store.dispatch("compareProviders/clearProvidersToCompare");
        return;
      }

      const providers = await getProvidersByNpi(npiList);

      store.dispatch("compareProviders/setProvidersToCompare", providers);
    } else {
      store.dispatch("compareProviders/clearProvidersToCompare");
    }
  }
}

onMounted(async () => {
  if (localStorage.getItem("compare")) {
    const npiList = JSON.parse(localStorage.getItem("compare") as string);

    if (!npiList.length) {
      return;
    }

    const providers = await getProvidersByNpi(npiList);

    providers.forEach((provider: any) => {
      store.dispatch("compareProviders/toggleProviderToCompare", provider);
    });
  }

  document.addEventListener("visibilitychange", handleVisibilityChange);
});

onUnmounted(() => {
  document.removeEventListener("visibilitychange", handleVisibilityChange);
});
</script>

<style scoped lang="scss">
@import "@/assets/style/mixins";

.compare-providers-widget {
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background-color: var(--color-white);
  border-top: 1px solid #e4e5e6;
  box-shadow: 0 -4px 12px 0 rgb(7 18 46 / 0.12);

  .content {
    position: relative;
    z-index: inherit;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding: 16px;
    background-color: var(--color-white);
    box-shadow: 0 -4px 12px 0 rgb(7 18 46 / 0.12);

    @include breakpoint-reverse(xsmall) {
      padding: 16px 44px;
    }

    @include breakpoint-reverse(small) {
      gap: 24px;
      justify-content: unset;
      box-shadow: none;
    }
  }

  .providers-list {
    display: flex;
    gap: 16px;
  }

  .actions-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .clear-btn {
    border-radius: 8px;
  }

  .compare-btn {
    border-radius: 8px;

    &::v-deep {
      .button-text {
        color: var(--color-white);
      }
    }
  }
}
</style>
