import { callPatientGenieApi } from "@/api/apiHelper";
import { IFetchResponse } from "@/types";
import { ISession } from "@/types/ISession";

export const start = async (): Promise<IFetchResponse<ISession>> => {
  return await callPatientGenieApi("post", "/session");
};

export const extendSession = async () => {
  return await callPatientGenieApi("post", "/extend-session");
};
