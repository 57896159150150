import { callPatientGenieApi } from "@/api/apiHelper";
import { IFetchResponse } from "@/types";
import {
  IOrganizationRequest,
  IOrganizationDetails,
  IOrganizationResults,
} from "@/types/IOrganizations";

export const getOrgSearchResults = async (
  body: IOrganizationRequest,
  controller?: AbortController
): Promise<IFetchResponse<IOrganizationResults>> => {
  return await callPatientGenieApi("post", "/organizations", body, {
    signal: controller ? controller.signal : undefined,
  });
};

export const getOrg = async (id: string): Promise<IFetchResponse<IOrganizationDetails>> => {
  return await callPatientGenieApi("get", `/organizations/${id}`);
};

export const getOrgProfile = async (id: string): Promise<IFetchResponse<IOrganizationDetails>> => {
  return await callPatientGenieApi("get", `/organizations/${id}`);
};
