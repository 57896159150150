import { callPatientGenieApi, callProfileApi } from "@/api/apiHelper";
import { IFetchResponse, IConversation, IConversationAnswer } from "@/types";
import { IConversationParams } from "@/types/IConversation";

export const start = async ({
  keyword,
  value,
  path,
  degree,
  groupId,
  language,
  name,
  taxonomy,
  type,
  unitId,
}: IConversationParams): Promise<IFetchResponse<IConversation>> => {
  return await callPatientGenieApi("post", "/conversation", {
    keyword,
    value,
    path,
    degree,
    groupId,
    language,
    name,
    taxonomy,
    type,
    unitId,
  });
};

export const sendAnswer = async (
  searchId: string,
  answer: IConversationAnswer
): Promise<IFetchResponse<IConversation>> => {
  return await callPatientGenieApi("put", `/conversation/${searchId}/send-answer`, answer);
};

export const get = async (conversationId: string): Promise<IFetchResponse<IConversation>> => {
  return await callPatientGenieApi("get", `/conversation/${conversationId}`);
};

export const revert = async (conversationId: string): Promise<IFetchResponse<IConversation>> => {
  return await callPatientGenieApi("put", `/conversation/${conversationId}/revert-answer`);
};

export const getPDF = async (conversationId: string): Promise<IFetchResponse<BlobPart>> => {
  return await callPatientGenieApi("get", `/results/${conversationId}/pdf`, {
    responseType: "arraybuffer",
  });
};

export const handleActionLink = async (key: string): Promise<IFetchResponse> => {
  return await callProfileApi("get", `/action-links/${key}`);
};
