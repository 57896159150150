<template>
  <div class="consumers-drawer" data-testid="consumer-drawer">
    <nav>
      <ul
        v-if="!getUser || isUserIncomplete"
        class="nav-list"
        data-testid="consumer-drawer-no-auth-nav"
      >
        <li class="nav-item">
          <router-link :to="{ path: '/consumer', hash: '#search' }"> Private search </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ path: '/consumer', hash: '#howitworks' }"> How it works </router-link>
        </li>
      </ul>
      <ul v-else class="nav-list" data-testid="consumer-drawer-auth-nav">
        <li v-for="userNavItem in getUserNavItems" :key="userNavItem.routeName" class="nav-item">
          <router-link :to="{ name: userNavItem.routeName }" class="nav-item-link">
            <v-icon size="24">{{ userNavItem.icon }}</v-icon>
            <span class="link-title">{{ userNavItem.title }}</span>
          </router-link>
        </li>
      </ul>
    </nav>

    <div class="auth-actions">
      <template v-if="!getUser">
        <AppButton
          :to="{ name: 'Login', query: { redirect: $route.path } }"
          class="auth-action-link"
          data-testid="consumer-drawer-login-link"
        >
          <v-icon color="var(--color-white)">
            {{ mdiAccountCircleOutline }}
          </v-icon>
          Log in
        </AppButton>
        <AppButton
          :to="{ name: 'Signup' }"
          outlined
          data-testid="consumer-drawer-signup-link"
          class="auth-action-link"
          >Sign up</AppButton
        >
      </template>
      <AppButton
        v-else
        outlined
        class="auth-action-link"
        data-testid="consumer-drawer-logout-btn"
        @click="handleLogout"
      >
        Log out
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router/composables";
import { mdiAccountCircleOutline } from "@mdi/js";

const getUser = computed(() => store.getters["user/getUser"]);
const getUserNavItems = computed(() => store.getters["patientProfile/getNavigationItems"]);
const isUserIncomplete = computed(
  () => getUser.value && (!getUser.value.isEmailConfirmed || getUser.value.onboarding?.length)
);

const router = useRouter();
const route = useRoute();

function handleLogout() {
  store.dispatch("user/signOut");
  store.dispatch("setIsDrawerOpened", false);
  if (route.name !== "Home") router.push({ name: "Home" });
}
</script>

<style lang="scss" scoped>
.consumers-drawer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .nav-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;

    .nav-item {
      a {
        display: block;
        padding: 12px 0;
        color: var(--color-grey-primary);
        font-weight: var(--font-weight-bolder);
      }

      .nav-item-link {
        display: grid;
        grid-template-columns: 20px auto;
        gap: 16px;
        align-items: center;

        .v-icon {
          color: var(--color-grey-primary);
        }
      }
    }
  }

  .auth-actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: auto;
    padding-top: 44px;

    .auth-action-link {
      height: 50px;
      border-radius: 8px;

      &::v-deep {
        .button-text {
          gap: 8px;
          font-weight: var(--font-weight-bolder);
          font-size: var(--font-medium);
        }
      }

      &:not(.v-btn--outlined) {
        &::v-deep {
          .button-text {
            color: var(--color-white);
          }
        }
      }
    }
  }
}
</style>
