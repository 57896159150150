import { IProviderDetails, IProviderShort } from "@/types/IProviders";

export const getFormattedPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return "";

  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  // formatted result is (###) ###-####
  return phoneNumber;
};

export const getFormattedProviderName = (
  provider: null | IProviderShort | IProviderDetails
): string => {
  if (!provider) return "";

  const formattedName = `${provider.firstName} ${provider.middleName} ${provider.lastName}`;

  if (provider.degrees && provider.degrees.length) {
    return `${formattedName}, ${provider.degrees.join(", ")}`;
  }

  return formattedName;
};

export const getFormattedOnlineProfile = (profile: string): { url: string; title: string } => {
  try {
    const url = new URL(profile);
    return {
      url: url.href,
      title: url.hostname,
    };
  } catch (e) {
    return {
      url: "",
      title: profile,
    };
  }
};
