import { callPatientGenieApi } from "@/api/apiHelper";
import { IExaminationConclusions, IFetchResponse, ISessionConclusionDetails } from "@/types";

export const get = async (
  conclusionId: string
): Promise<IFetchResponse<ISessionConclusionDetails>> => {
  return await callPatientGenieApi("post", `/conclusion-details/${conclusionId}`);
};

export const getListByConversationId = async (
  conversationId: string
): Promise<IFetchResponse<IExaminationConclusions[]>> => {
  return await callPatientGenieApi("get", `/conversation/${conversationId}/triage-conclusions`);
};
