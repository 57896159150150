import { callPatientGenieApi } from "@/api/apiHelper";
import { IFetchResponse, ILocationResponse } from "@/types";

export const get = async (
  latitude: number,
  longitude: number
): Promise<IFetchResponse<ILocationResponse[]>> => {
  return await callPatientGenieApi("post", `/locations`, {
    latitude,
    longitude,
  });
};
