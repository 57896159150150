export const PATHS_WITH_ASSUMPTIONS = [
  "procedure",
  "disease",
  "fuzzy",
  "focus_area",
  "symptom",
  "specialty",
];

export const WAITING_TEXT =
  "Thank you for your patience! We acknowledge that you may be eagerly waiting for crucial health information, " +
  "and we want to assure you that your concerns and needs are a top priority for us. Delivering accurate and " +
  "current details may require a bit of time as we work diligently behind the scenes to gather and verify the " +
  "data necessary for your inquiry.\n" +
  "We truly appreciate your understanding and cooperation while we strive to serve you in the best possible " +
  "manner. Your satisfaction is our goal, and we want you to know that we are committed to providing you with " +
  "the most thorough and timely information available. We will have this information prepared and delivered to " +
  "you as soon as possible, so please hang in there as we are doing everything we can to ensure your experience " +
  "with us is nothing but exceptional!";
