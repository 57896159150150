import { callPatientGenieApi } from "@/api/apiHelper";
import { IFetchResponse } from "@/types";
import {
  IProvidersRequest,
  IProvidersResults,
  IProviderDetails,
  IProviderToCompare,
} from "@/types/IProviders";

export const getList = async (
  body: IProvidersRequest,
  controller?: AbortController
): Promise<IFetchResponse<IProvidersResults>> => {
  return await callPatientGenieApi("post", "/providers", body, {
    signal: controller ? controller.signal : undefined,
  });
};

export const get = async (
  npi: string,
  location?: {
    latitude: number;
    longitude: number;
  }
): Promise<IFetchResponse<IProviderDetails>> => {
  return await callPatientGenieApi("get", `/providers/${npi}`, {
    params: {
      latitude: location?.latitude || 0,
      longitude: location?.longitude || 0,
    },
  });
};

export const getProfile = async (
  npi: string,
  location: {
    latitude: number;
    longitude: number;
  }
): Promise<IFetchResponse<IProviderDetails>> => {
  return await callPatientGenieApi("get", `/provider-profile/${npi}`, {
    params: {
      latitude: location.latitude,
      longitude: location.longitude,
    },
  });
};

export const getProvidersToCompare = async (
  npiList: string[]
): Promise<IFetchResponse<IProviderToCompare[]>> => {
  return await callPatientGenieApi("post", "/compare-providers", npiList);
};
