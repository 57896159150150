<template>
  <div class="app-error-notification">
    <v-icon color="var(--color-white)" size="26">{{ mdiAlertCircleOutline }}</v-icon>
    <span>{{ getErrorNotification.text || "Oops! Something went wrong..." }}</span>
    <button class="btn-close" @click="hideNotification">
      <img src="/img/close-icon.svg" alt="" />
    </button>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { mdiAlertCircleOutline } from "@mdi/js";

export default Vue.extend({
  name: "AppErrorNotification",
  data() {
    return {
      mdiAlertCircleOutline,
    };
  },
  computed: {
    ...mapGetters(["getErrorNotification"]),
  },
  created() {
    setTimeout(() => {
      this.hideNotification();
    }, 7000);
  },
  methods: {
    ...mapActions(["setErrorNotification"]),
    hideNotification() {
      this.setErrorNotification(null);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.app-error-notification {
  position: fixed;
  top: 30px;
  right: 100px;
  z-index: 10001;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 500px;
  padding: 16px;
  color: var(--color-white);
  font-weight: var(--font-weight-bold);
  background-color: var(--color-orange);
  border-radius: 16px;
  box-shadow: var(--default-box-shadow);
  transform: translate(0, 50%);

  @include breakpoint(xsmall) {
    top: 20px;
    right: 50%;
    width: 90%;
    transform: translate(50%);
  }

  .btn-close {
    display: flex;
    margin-left: auto;
  }
}
</style>
