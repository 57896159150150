import "@/plugins/firebase";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import Vue from "vue";
import "hacktimer";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createPinia, PiniaVuePlugin } from "pinia";
import vuetify from "./plugins/vuetify";
import "./plugins/components";
import "./registerServiceWorker";
import VueAgile from "vue-agile";
import GmapVue from "gmap-vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";

Vue.use(Donut);
import { initSchedulerParams } from "@/services/zocDocScheduler";

declare global {
  interface Window {
    organization: string;
    apiUrl: string;
    partnerUrl: string;
    integrationUrl: string;
  }
}

if (process.env.VUE_APP_ZOC_DOC_KEY) initSchedulerParams(process.env.VUE_APP_ZOC_DOC_KEY);

if (process.env.VUE_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    appVersion: process.env.VUE_APP_CODE_VERSION,
    releaseStage: process.env.VUE_APP_STAGE,
    onError: function (event: any) {
      if (
        /Loading.*chunk.*failed./i.test(event.errors[0].errorMessage) ||
        /ChunkLoadError:.*failed./i.test(event.errors[0].errorMessage)
      ) {
        return false;
      }
    },
  });

  const bugsnagVue = Bugsnag.getPlugin("vue");
  bugsnagVue?.installVueErrorHandler(Vue);
}

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_API_KEY,
    libraries: "places",
    language: "en",
  },
});

Vue.use(VueAgile);
Vue.prototype.$appConfig = {};

try {
  Vue.prototype.$appConfig.isIntegration = process.env.VUE_APP_INTEGRATION;
  Vue.prototype.$appConfig.isEmbed = window.self !== window.top;
} catch (e) {
  console.error(e);
  Vue.prototype.$appConfig.isEmbed = false;
}

Vue.config.productionTip = false;

let app: Vue | null = null;
onAuthStateChanged(getAuth(), () => {
  if (app) return;

  app = new Vue({
    router,
    store,
    pinia,
    vuetify,
    render: (h) => h(App),
  }).$mount("#pg-embed");
});
