<template>
  <div class="compare-providers-widget-provider-chip" :class="{ 'list-view': listView }">
    <div class="info-wrapper">
      <button class="bottom-sheet-trigger-btn" @click="openBottomSheet">
        <img
          :src="
            getImageById(
              provider.avatar,
              120,
              120,
              provider.gender === 'f' ? 'avatarFemale' : 'avatarMale'
            )
          "
          class="provider-photo"
          alt=""
        />
      </button>

      <p class="provider-name">{{ getFormattedProviderName(provider) }}</p>
    </div>

    <v-btn icon class="remove-btn" @click="removeProvider">
      <v-icon color="var(--color-grey-primary)">{{ mdiClose }}</v-icon>
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { mdiClose } from "@mdi/js";
import { getImageById } from "@/services/images";
import { IProviderToCompare } from "@/types/IProviders";
import { getFormattedProviderName } from "@/services/providerHelpers";
import store from "@/store";
import { useVuetify } from "@/composables/vuetify";

const props = withDefaults(
  defineProps<{
    provider: IProviderToCompare;
    listView?: boolean;
  }>(),
  {
    listView: false,
  }
);

function removeProvider() {
  store.dispatch("compareProviders/toggleProviderToCompare", props.provider);
}

const { breakpoint } = useVuetify();

function openBottomSheet() {
  if (breakpoint.lgAndDown) {
    store.dispatch("compareProviders/setIsCompareBottomSheetOpened", true);
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.compare-providers-widget-provider-chip {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 50px;
  height: 50px;
  border: 1px solid var(--color-grey-tertiary);
  border-radius: 8px;

  @include breakpoint-reverse(small) {
    width: 226px;
    height: 76px;
    padding: 8px;
    border-radius: 16px;
  }

  @include breakpoint-reverse(large) {
    width: 334px;
  }

  .info-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;

    .bottom-sheet-trigger-btn {
      display: flex;
      cursor: default;

      @include breakpoint-reverse(small) {
        pointer-events: none;
      }
    }

    .provider-photo {
      width: 100%;
      height: 100%;
      border-radius: 8px;

      @include breakpoint-reverse(small) {
        width: 60px;
        height: 60px;
      }
    }

    .provider-name {
      display: none;
      margin: 0;
      overflow: hidden;
      color: var(--color-green-secondary);
      font-weight: var(--font-weight-bolder);
      white-space: nowrap;
      text-overflow: ellipsis;

      @include breakpoint-reverse(small) {
        display: block;
      }
    }
  }

  .remove-btn {
    display: none;

    @include breakpoint-reverse(small) {
      display: block;
    }
  }

  &.list-view {
    width: 100%;
    height: auto;
    padding: 20px 16px;
    border: none;
    border-top: 1px solid #e4e5e6;
    border-radius: 0;

    .provider-photo {
      width: 50px;
      height: 50px;
    }

    .provider-name {
      display: block;
    }

    .remove-btn {
      display: block;
    }
  }
}
</style>
