import { Commit } from "vuex";
import { IProviderToCompare, IProviderShort, IProviderDetails } from "@/types/IProviders";
import Vuetify from "@/plugins/vuetify";

interface ICompareProvidersState {
  providersToCompare: IProviderToCompare[] | IProviderDetails[];
  isCompareBottomSheetOpened: boolean;
}

interface Getter {
  getProvidersToCompare: IProviderToCompare[] | IProviderDetails[];
  getVisibleProvidersToCompare: IProviderToCompare[] | IProviderDetails[];
  getIsCompareBottomSheetOpened: boolean;
  getMaxProvidersCountToCompare: number;
  getIsCompareDisabledForProvider: (provider: IProviderShort | IProviderDetails) => boolean;
  getIsProviderAddedToCompare: (provider: IProviderShort | IProviderDetails) => boolean;
}

export default {
  namespaced: true,
  state: (): ICompareProvidersState => ({
    providersToCompare: [],
    isCompareBottomSheetOpened: false,
  }),
  getters: {
    getProvidersToCompare: (state: ICompareProvidersState) => {
      return state.providersToCompare || [];
    },
    getMaxProvidersCountToCompare: () => {
      return Vuetify.framework.breakpoint.lgAndDown ? 2 : 3;
    },
    getVisibleProvidersToCompare: (state: ICompareProvidersState, getters: Getter) => {
      return state.providersToCompare?.slice(0, getters.getMaxProvidersCountToCompare) || [];
    },
    getIsCompareBottomSheetOpened: (state: ICompareProvidersState) => {
      return state.isCompareBottomSheetOpened;
    },
    getIsCompareDisabledForProvider:
      (state: ICompareProvidersState, getters: Getter) =>
      (provider: IProviderShort | IProviderDetails) => {
        return (
          getters.getProvidersToCompare?.length >= getters.getMaxProvidersCountToCompare &&
          !getters.getIsProviderAddedToCompare(provider)
        );
      },
    getIsProviderAddedToCompare:
      (state: ICompareProvidersState, getters: Getter) =>
      (provider: IProviderShort | IProviderDetails) => {
        return !!getters.getVisibleProvidersToCompare?.find((p) => p.npi === provider.npi);
      },
  },
  mutations: {
    TOGGLE_PROVIDER_TO_COMPARE(
      state: ICompareProvidersState,
      provider: IProviderShort | IProviderDetails
    ) {
      if (!state.providersToCompare?.some((p) => p.npi === provider.npi)) {
        state.providersToCompare = [...state.providersToCompare, provider];
      } else {
        state.providersToCompare = state.providersToCompare?.filter((p) => p.npi !== provider.npi);
      }

      localStorage.setItem("compare", JSON.stringify(state.providersToCompare?.map((p) => p.npi)));
    },
    CLEAR_PROVIDERS_TO_COMPARE(state: ICompareProvidersState) {
      state.providersToCompare = [];

      localStorage.removeItem("compare");
    },
    SET_IS_COMPARE_BOTTOM_SHEET_OPENED(
      state: ICompareProvidersState,
      isCompareBottomSheetOpened: boolean
    ) {
      state.isCompareBottomSheetOpened = isCompareBottomSheetOpened;
    },
    SET_PROVIDERS_TO_COMPARE(state: ICompareProvidersState, providers: IProviderToCompare[]) {
      state.providersToCompare = providers;
    },
  },
  actions: {
    toggleProviderToCompare(
      { commit }: { commit: Commit },
      provider: IProviderShort | IProviderDetails
    ) {
      commit("TOGGLE_PROVIDER_TO_COMPARE", provider);
    },
    clearProvidersToCompare({ commit }: { commit: Commit }) {
      commit("CLEAR_PROVIDERS_TO_COMPARE");
    },
    setIsCompareBottomSheetOpened(
      { commit }: { commit: any },
      isCompareBottomSheetOpened: boolean
    ) {
      commit("SET_IS_COMPARE_BOTTOM_SHEET_OPENED", isCompareBottomSheetOpened);
    },
    setProvidersToCompare({ commit }: { commit: Commit }, providers: IProviderToCompare[]) {
      commit("SET_PROVIDERS_TO_COMPARE", providers);
    },
  },
};
