<template>
  <div>
    <transition name="slide">
      <div v-if="isBottomSheetOpen" class="compare-providers-widget-bottom-sheet">
        <div class="close-btn-row">
          <AppButton height="32" text class="close-btn" @click="close">
            <span>Close</span>
            <v-icon size="20">{{ mdiClose }}</v-icon>
          </AppButton>
        </div>

        <div>
          <CompareProvidersWidgetProviderChip
            v-for="provider in providersToCompare"
            :key="provider.npi"
            :provider="provider"
            list-view
          />
        </div>
      </div>
    </transition>

    <div v-if="isBottomSheetOpen" class="overlay"></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import store from "@/store";
import { mdiClose } from "@mdi/js";
import { useVuetify } from "@/composables/vuetify";
import { AppButton } from "pg-ui";
import CompareProvidersWidgetProviderChip from "@/components/Compare/CompareProvidersWidgetProviderChip.vue";

const { breakpoint } = useVuetify();

const isBottomSheetOpen = computed(
  () => store.getters["compareProviders/getIsCompareBottomSheetOpened"] && breakpoint.lgAndDown
);

const providersToCompare = computed(
  () => store.getters["compareProviders/getVisibleProvidersToCompare"]
);

function close() {
  store.dispatch("compareProviders/setIsCompareBottomSheetOpened", false);
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.slide-leave-active,
.slide-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(200px);
}

.compare-providers-widget-bottom-sheet {
  position: absolute;
  right: 0;
  bottom: 83px;
  left: 0;
  background-color: var(--color-white);

  @include breakpoint-reverse(xsmall) {
    padding: 0 44px;
  }

  .close-btn-row {
    padding: 8px 16px;

    .close-btn {
      display: flex;
      margin-left: auto;
      padding: 0 6px;
      border-radius: 8px;

      &::v-deep .button-text {
        display: flex;
        gap: 10px;
        align-items: center;
        font-weight: var(--font-weight-medium);
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: var(--color-grey-secondary);
  opacity: 0.6;
}
</style>
