import { imageApi } from "@/plugins/axios";
const BASE_URL = process.env.VUE_APP_IMG_API_URL;

interface defaultImg {
  location: string;
  avatarFemale: string;
  avatarMale: string;
  avatarOrg: string;
  avatarUser: string;
}

const defaultImg: defaultImg = {
  location: "/img/img-placeholder.png",
  avatarFemale: "/icons/doctor-female.svg",
  avatarMale: "/icons/doctor-male.svg",
  avatarOrg: "/img/org-default-avatar.png",
  avatarUser: "/img/default-user-avatar.png",
};

async function saveImage(file: File) {
  if (file.size > 10000000) {
    return Promise.reject(new Error("Use an image smaller than 10Mb"));
  }
  const formData = new FormData();
  formData.append("file", file);

  const { data } = await imageApi.post("/", formData);

  return data;
}

function getImageById(id: string, width: string | number, height: string | number, type: string) {
  if (!id) return defaultImg[type as keyof defaultImg];
  let imageUrl = `${BASE_URL}/${id}`;
  if (width) imageUrl += `/${width}`;
  if (width && height) imageUrl += `/${height}`;
  return imageUrl;
}

export { getImageById, saveImage };
