<template>
  <div :class="{ focused: isFieldFocused }" class="autocomplete">
    <input
      ref="searchField"
      :value="searchReason"
      :class="[{ focused: isFieldFocused }, { 'focused-empty': !results.length }]"
      placeholder="Enter symptom, procedure, doctor…"
      class="field"
      maxlength="255"
      data-testid="search-autocomplete-field"
      @input="($event) => (searchReason = $event.target.value)"
      @paste="$emit('fetchAutocompleteResults')"
      @keyup="handleFieldKeyup"
      @focus="focusField"
      @keyup.esc="handleInputBlur"
      @keyup.enter="startNewSessionBasedOnFocusedItem"
      @keydown.up.exact.prevent="handleArrowKeys"
      @keydown.down.exact.prevent="handleArrowKeys"
    />

    <button
      v-if="isFieldFocusedOnMobile"
      class="mobile-back-btn"
      aria-label="back"
      @click="handleInputBlur"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.26495 10.7351L6 12L0 6L6 0L7.26495 1.26495L3.42448 5.10541H12V6.89459H3.42448L7.26495 10.7351Z"
          fill="#1448C2"
        />
      </svg>
    </button>

    <button
      v-if="searchReason && isFieldFocused"
      class="field-button clear"
      aria-label="clear"
      @click="searchReason = ''"
    >
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6706 0.00146484L8.68457 6.00239L2.69995 0.00146484L0.705078 2.00177L6.6897 8.00269L0.705078 14.0036L2.69995 16.0039L8.68457 10.003L14.6706 16.0039L16.6655 14.0036L10.6809 8.00269L16.6655 2.00177L14.6706 0.00146484Z"
          fill="var(--color-purple-primary)"
        />
      </svg>
    </button>

    <button
      v-if="!isFieldFocusedOnMobile"
      class="field-button"
      aria-label="search"
      @click="startNewSessionBasedOnFocusedItem"
    >
      <svg
        v-if="searchReason"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="field-button-icon"
      >
        <path
          d="M6.3134 14.3134L8 16L16 8L8 0L6.3134 1.6866L11.434 6.80722H0V9.19278H11.434L6.3134 14.3134Z"
          fill=""
        />
      </svg>
      <svg
        v-else
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="field-button-icon"
      >
        <path
          d="M8 16C9.77498 15.9996 11.4988 15.4054 12.897 14.312L17.293 18.708L18.707 17.294L14.311 12.898C15.405 11.4997 15.9996 9.77544 16 8C16 3.589 12.411 0 8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16ZM8 2C11.309 2 14 4.691 14 8C14 11.309 11.309 14 8 14C4.691 14 2 11.309 2 8C2 4.691 4.691 2 8 2Z"
          fill=""
        />
      </svg>
    </button>

    <div ref="dropdown" v-show="isFieldFocused">
      <article
        tabindex="0"
        v-for="(result, index) in results"
        :key="index"
        class="result"
        data-testid="autocomplete-result-item"
        @click="clickResult(result)"
        @keydown.up.exact.prevent="focusPrevious"
        @keydown.down.exact.prevent="focusNext"
        @keyup.enter="startNewSessionBasedOnFocusedItem"
      >
        <div class="word">
          <div class="value">
            <svg
              width="14"
              height="13"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="icon-arrow"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 0.0603027V5.75757V6.25738V6.75757H8.3167L5.98827 9.086C5.79301 9.28126 5.79301 9.59784 5.98827 9.7931C6.18354 9.98836 6.50012 9.98836 6.69538 9.7931L9.87736 6.61112C10.0726 6.41586 10.0726 6.09928 9.87736 5.90402L6.69538 2.72203C6.50012 2.52677 6.18354 2.52677 5.98827 2.72203C5.79301 2.9173 5.79301 3.23388 5.98827 3.42914L8.3167 5.75757H1L1 0.0603027H0Z"
                fill=""
              />
            </svg>
            <div class="text">
              <div class="text-title">
                {{ result.title }}
                <span class="text-subtitle">({{ result.category }})</span>
              </div>
              <div v-if="result.subTitle" class="text-subtitle">
                {{ result.subTitle }}
              </div>
            </div>
          </div>

          <div class="type">
            <AppIcon
              :path="`/icons/${categoryIcons[result.category]}.svg`"
              width="16"
              height="16"
              fill="var(--color-grey-tertiary)"
              class="icon"
            />
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { IAutocompleteResult } from "@/types";
import AppIcon from "@/components/AppComponents/AppIcon.vue";

export default Vue.extend({
  name: "AppSearchBarAutocomplete",
  components: {
    AppIcon,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    results: {
      type: Array as () => IAutocompleteResult[],
      required: true,
    },
    isFocused: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isFieldFocused: false,
      categoryIcons: {
        disease: "disease",
        doctor: "doctor",
        procedure: "procedure",
        specialty: "specialty",
        symptom: "symptom",
        "focus area": "focus-area",
        organization: "organization",
      },
      focusedIndex: -1,
      keysToExclude: ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"],
    };
  },
  computed: {
    searchReason: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        if (!value.trim()) this.focusedIndex = -1;
        this.$emit("update:value", value);
      },
    },
    isFieldFocusedOnMobile(): boolean {
      return this.isFieldFocused && this.$vuetify.breakpoint.xs;
    },
    dropdown(): HTMLElement {
      return this.$refs.dropdown as HTMLElement;
    },
  },
  async mounted() {
    if (this.isFocused) {
      await this.$nextTick();
      (this.$refs.searchField as HTMLElement).focus();
    }

    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleClickOutside(e: Event) {
      if ((e.target as HTMLElement)?.id === "autocomplete-overlay") {
        this.handleInputBlur();
      }
    },
    handleArrowKeys() {
      if (this.isFieldFocused && this.dropdown) {
        this.focusElement();
      }
    },
    focusPrevious() {
      if (this.focusedIndex > 0) {
        this.focusedIndex = this.focusedIndex - 1;
      }

      this.focusElement();
    },
    focusNext() {
      if (this.focusedIndex < this.results.length - 1) {
        this.focusedIndex = this.focusedIndex + 1;
      }

      this.focusElement();
    },
    focusElement() {
      if (this.focusedIndex < 0) this.focusedIndex = 0;
      const nextElementToFocus = this.dropdown.children[this.focusedIndex] as HTMLElement;

      if (!nextElementToFocus) return;

      nextElementToFocus.focus();
      this.searchReason = this.results[this.focusedIndex].title;
    },
    handleFieldKeyup(e: KeyboardEvent) {
      if (!this.searchReason.trim()) return;

      if (!this.keysToExclude.includes(e.key)) {
        this.$emit("fetchAutocompleteResults");
      }
    },
    handleInputBlur() {
      // to prevent blur before click
      // TODO refactor?
      setTimeout(() => {
        this.isFieldFocused = false;
        this.$emit("blur");
      }, 200);
    },
    focusField() {
      this.focusedIndex = -1;
      this.isFieldFocused = true;
      this.$emit("focus");

      if (this.searchReason.trim()) {
        this.$emit("fetchAutocompleteResults");
      }
    },
    clickResult(result: IAutocompleteResult) {
      this.searchReason = result.title;
      this.isFieldFocused = false;
      this.$emit("startNewSession", result);
    },
    startNewSessionBasedOnFocusedItem() {
      this.isFieldFocused = false;

      if (this.focusedIndex < 0) {
        this.$emit("startNewSession", this.searchReason);
      } else {
        this.$emit("startNewSession", this.results[this.focusedIndex]);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.autocomplete {
  position: relative;
  top: 0;
  width: 100%;
  height: fit-content;
  font-weight: var(--font-weight-medium);
  background: transparent;
  border: 0;
  border-radius: 28px;
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: var(--default-box-shadow);
  }

  .field {
    z-index: 2;
    width: 100%;
    height: 60px;
    padding: 20px 100px 20px 24px;
    text-overflow: ellipsis;
    background-color: var(--color-white);
    border-radius: 40px;
    outline: none;

    @include breakpoint(xsmall) {
      padding: 22px 48px 22px 22px;
    }

    &.focused {
      position: relative;
      z-index: 10001;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.focused-empty {
      border-radius: 40px;
    }
  }

  .mobile-back-btn {
    position: absolute;
    top: 2px;
    left: 10px;
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
  }

  .field-button {
    position: absolute;
    top: 20px;
    right: 24px;

    &.clear {
      right: 50px;
      margin-right: 10px;
    }

    .field-button-icon {
      fill: var(--color-purple-primary);

      &.disabled {
        fill: var(--color-grey-secondary);
      }
    }
  }

  .icon-arrow {
    min-width: 14px;
    margin-right: 8px;
    fill: var(--color-grey-primary);
  }

  .result {
    padding: 10px 24px;
    outline: none;
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: var(--color-purple-secondary);
    }

    &:hover .icon-arrow,
    &:focus .icon-arrow {
      fill: var(--color-purple-primary);
    }

    &:hover .word .text,
    &:focus .word .text {
      color: var(--color-purple-primary);
    }

    &:last-child {
      border-radius: 0 0 24px 24px;
    }
  }

  .word {
    display: flex;
    justify-content: space-between;
    color: var(--color-grey-primary);
    font-size: var(--font-medium);
    transition: all 0.2s ease;

    .value {
      display: flex;
      align-items: center;

      .text-subtitle {
        color: var(--color-grey-tertiary);
        font-size: var(--font-small);
      }
    }

    .type {
      display: flex;
      align-items: center;

      .name {
        margin-right: 4px;
        color: var(--color-grey-tertiary);
        font-weight: var(--font-weight-medium);
        font-size: var(--font-small);

        @include breakpoint(xsmall) {
          display: none;
        }
      }

      .icon {
        width: 16px;
        min-width: 16px;
      }
    }
  }

  &.focused {
    position: relative;
    z-index: 10001;
    background-color: var(--color-white);
    box-shadow: 0 3px 3px rgb(0 0 0 / 0.1);

    .field-button {
      z-index: 10001;
    }
  }
}
</style>
