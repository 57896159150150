import Vue from "vue";

import AppButton from "@/components/AppComponents/AppButton.vue";
import AppProgressBar from "@/components/AppComponents/AppProgressBar.vue";
import AppDialog from "@/components/AppComponents/AppDialog.vue";
import AppMoreButton from "@/components/AppComponents/AppMoreButton.vue";

Vue.component("AppButton", AppButton);
Vue.component("AppProgressBar", AppProgressBar);
Vue.component("AppDialog", AppDialog);
Vue.component("AppMoreButton", AppMoreButton);
