<template>
  <div class="header-website-toggle" data-testid="header-website-toggle">
    <div class="content">
      <router-link
        :to="{ name: 'Home' }"
        class="header-website-toggle-link"
        data-testid="header-website-toggle-business-link"
        :class="{ active: !route.meta.isConsumer }"
      >
        For organizations
      </router-link>
      <router-link
        :to="{ name: 'ConsumersHome' }"
        class="header-website-toggle-link"
        data-testid="header-website-toggle-consumer-link"
        :class="{ active: route.meta.isConsumer }"
      >
        For consumers
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router/composables";

const route = useRoute();
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.header-website-toggle {
  padding: 8px 0;
  border-bottom: 2px solid #f2f2f3;

  .content {
    display: flex;
    gap: 24px;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 44px;

    @include breakpoint(small) {
      padding: 0 20px;
    }
  }

  .header-website-toggle-link {
    position: relative;
    color: var(--color-grey-primary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-small);

    &::before {
      position: absolute;
      bottom: -10px;
      display: none;
      width: 100%;
      height: 2px;
      background-color: var(--color-purple-primary);
      content: "";
    }

    &.active {
      &::before {
        display: block;
      }
    }
  }
}
</style>
