<template>
  <v-dialog
    v-model="isOpen"
    :width="width"
    :max-width="maxWidth"
    :fullscreen="fullscreen"
    :transition="transition"
    :overlay-color="overlayColor"
    :content-class="`app-dialog ${contentClass}`"
    @click:outside="$emit('close')"
  >
    <slot />
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "AppDialog",
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    maxWidth: {
      type: String,
      required: false,
      default: "720",
    },
    width: {
      type: String,
      required: false,
      default: "",
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    contentClass: {
      type: String,
      required: false,
      default: "",
    },
    overlayColor: {
      type: String,
      required: false,
      default: "",
    },
    transition: {
      type: [String, Boolean],
      required: false,
      default: "dialog-transition",
    },
  },
  data() {
    return {
      isOpen: this.isDialogOpen,
    };
  },
  watch: {
    isDialogOpen(value: boolean) {
      this.isOpen = value;
    },
  },
  mounted() {
    document.addEventListener("keydown", this.closeModalHandler);
  },
  destroyed() {
    document.removeEventListener("keydown", this.closeModalHandler);
  },
  methods: {
    closeModalHandler(e: KeyboardEvent) {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.v-dialog {
  &__content::v-deep .app-dialog {
    position: relative;
    background-color: var(--color-white);
    border-radius: 24px;
    scrollbar-width: 0;

    @include breakpoint(xsmall) {
      border-radius: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .close-icon {
      position: absolute;
      top: 16px;
      right: 16px;

      @include breakpoint(xsmall) {
        display: none;
      }
    }
  }

  &__content::v-deep .v-dialog--fullscreen {
    border-radius: 0;
  }
}
</style>
