import api from "@/api";
import { IConversationParams } from "@/types/IConversation";
import { NavigationGuardNext, Route } from "vue-router";

async function startNewSession(sessionParams: IConversationParams, next: NavigationGuardNext) {
  const { data } = await api.conversation.start(sessionParams);

  if (!data) {
    next("/");
    return;
  }

  next({
    name: "Conversation",
    params: { id: data.id },
  });
}

export async function handleShortUrl(to: Route, from: Route, next: NavigationGuardNext) {
  const key = to.params.id;

  if (!key) {
    next("/");
    return;
  }

  const { data } = await api.conversation.handleActionLink(key);

  if (!data) {
    next("/");
    return;
  }

  startNewSession(data, next);
}
